import Label from '@myob/myob-widgets/lib/components/Label/Label'
import React from 'react'

export type TemplatePlaceholderProps = {
  readonly value: string
}

export const TemplateEditorDecorator: React.FunctionComponent<
  TemplatePlaceholderProps
> = props => {
  return (
    <Label type="boxed" size="default">
      {props.value}
    </Label>
  )
}
