import React from 'react'
import styled from 'styled-components'
import {
  flxFontSizeHeadingLarge,
  flxFontSizeTextSmall,
  flxSpacingSmall,
} from '@myob/myob-styles/dist/design-tokens/js/design-tokens'

const StyledImageContainer = styled.div``
const StyledContent = styled.div``
const StyledHeading = styled.h1`
  font-size: ${flxFontSizeHeadingLarge};
  margin-bottom: ${flxSpacingSmall};
`
const StyledInnerContainer = styled.div`
  text-align: center;
  font-size: ${flxFontSizeTextSmall};
`
const StyledContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
`

export const SplashPage = props => {
  return (
    <StyledContainer className={props.className}>
      <StyledInnerContainer className={'container'}>
        <StyledImageContainer>{props.splashImage}</StyledImageContainer>
        <StyledHeading>{props.title}</StyledHeading>
        <StyledContent>{props.children}</StyledContent>
      </StyledInnerContainer>
    </StyledContainer>
  )
}
