import React from 'react'
import styled from 'styled-components'
import { Spacing } from './enums/Spacing'

const StyledHeading1 = styled.h1`
  margin-bottom: ${Spacing.MEDIUM};
`

const StyledHeading2 = styled.h2`
  margin-bottom: ${Spacing.MEDIUM};
`

const StyledHeading3 = styled.h3`
  margin-bottom: ${Spacing.MEDIUM};
`

const StyledHeading4 = styled.h4`
  margin-bottom: ${Spacing.MEDIUM};
`

const StyledHeading5 = styled.h5`
  margin-bottom: ${Spacing.MEDIUM};
`

export type HeadingProps = {
  readonly className?: string
  readonly level: 1 | 2 | 3 | 4 | 5
  readonly children?: React.ReactNode
}

export const Heading: React.FunctionComponent<HeadingProps> = props => {
  switch (props.level) {
    case 1:
      return (
        <StyledHeading1 className={props.className}>
          {props.children}
        </StyledHeading1>
      )
    case 2:
      return (
        <StyledHeading2 className={props.className}>
          {props.children}
        </StyledHeading2>
      )
    case 3:
      return (
        <StyledHeading3 className={props.className}>
          {props.children}
        </StyledHeading3>
      )
    case 4:
      return (
        <StyledHeading4 className={props.className}>
          {props.children}
        </StyledHeading4>
      )
    case 5:
      return (
        <StyledHeading5 className={props.className}>
          {props.children}
        </StyledHeading5>
      )
    default:
      return (
        <StyledHeading1 className={props.className}>
          {props.children}
        </StyledHeading1>
      )
  }
}
