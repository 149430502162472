import axios from 'axios'
import { AuthTokenService } from './../AuthTokenService'
import { IAuthTokenService } from './../interfaces/IAuthTokenService'

export const createAuthTokenService = (url: string): IAuthTokenService => {
  return new AuthTokenService(
    axios.create({
      baseURL: url,
    })
  )
}
