import MYOBLogo from '@myob/myob-widgets/lib/components/MYOBLogo/MYOBLogo'
import Navigation from '@myob/myob-widgets/lib/components/Navigation/Navigation'
import {Schedule} from '@myob/sme-cashin-reminders-domain'
import React from 'react'
import styled from 'styled-components'
import {PartialBusinessPreferences} from '../../../services/models/PartialBusinessPreferences'
import {ConsumerRenderer} from '../../widgets/ConsumerRenderer'
import {TabbedPreferences} from './TabbedPreferences'

const StyledNavigationContainer = styled.div`
  .Collapsible {
    display: none;
  }
`

export type PreferencePageProps = {
    readonly className?: string
    readonly businessPreferences: PartialBusinessPreferences
    readonly locked: boolean
    readonly onUpdateDailyEnabled: () => Promise<void>
    readonly onUpdateDailyAlgorithm: () => Promise<void>
    readonly onUpdateDailySchedule: (
        schedule: Schedule,
        id?: number
    ) => Promise<void>
    readonly onDeleteDailySchedule: (schedule: Schedule) => Promise<void>
    readonly onUpdateMonthlyEnabled: () => Promise<void>
    readonly onUpdateMonthlyAlgorithm: () => Promise<void>
    readonly activeTab: string
    readonly showModal: boolean
    readonly changeTab: any // babel doesn't like immediately destructured parameters
    readonly checkToShowModal: (
        isEnabled: boolean,
        onUpdate: () => Promise<void>
    ) => void
    readonly hideModal: () => void
    readonly cancelModal: () => void
    readonly trackHelpClick: (analyticsLabel: string) => void
    readonly isSmep?: boolean
    readonly children?: React.ReactNode
}

export const PreferencePage: React.FunctionComponent<PreferencePageProps> = props => {
    return (
        <div>
            <ConsumerRenderer
                ARL={null}
                Essentials={
                    <StyledNavigationContainer>
                        <Navigation
                            brand={
                                <Navigation.Brand url={'#'} width={'73px'}>
                                    <MYOBLogo type={'gradient'}/>
                                </Navigation.Brand>
                            }
                            primary={[]}
                            secondary={[]}
                        />
                    </StyledNavigationContainer>
                }
            />
            <TabbedPreferences
                isSmep={props.isSmep}
                children={props.children}
                locked={props.locked}
                businessPreferences={props.businessPreferences}
                onUpdateDailyEnabled={props.onUpdateDailyEnabled}
                onUpdateDailyAlgorithm={props.onUpdateDailyAlgorithm}
                onUpdateDailySchedule={props.onUpdateDailySchedule}
                onDeleteDailySchedule={props.onDeleteDailySchedule}
                onUpdateMonthlyEnabled={props.onUpdateMonthlyEnabled}
                onUpdateMonthlyAlgorithm={props.onUpdateMonthlyAlgorithm}
                activeTab={props.activeTab}
                showModal={props.showModal}
                changeTab={props.changeTab}
                checkToShowModal={props.checkToShowModal}
                hideModal={props.hideModal}
                trackHelpClick={props.trackHelpClick}
                cancelModal={props.cancelModal}
            />
        </div>
    )
}
