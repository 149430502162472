export enum AnalyticsEvent {
  CUSTOMER_ERROR_VIEW = 'invoice-reminders-customer-error-view',
  CUSTOMER_DAILY_STATUS_CHANGE = 'invoice-reminders-customer-check',
  CUSTOMER_MONTHLY_STATUS_CHANGE = 'invoice-reminders-customer-summary-check',
  CUSTOMER_VIEW = 'invoice-reminders-customer-view',
  GLOBAL_DAILY_ENABLED_CHANGE = 'invoice-reminders-global-check',
  GLOBAL_DAILY_ALGORITHM_CHANGE = 'invoice-reminders-daily-algorithm-change',
  GLOBAL_DAILY_SCHEDULE_CHANGE = 'invoice-reminders-daily-schedule-change',
  GLOBAL_MONTHLY_ENABLED_CHANGE = 'invoice-reminders-global-summary-check',
  GLOBAL_MONTHLY_ALGORITHM_CHANGE = 'invoice-reminders-monthly-algorithm-change',
  GLOBAL_VIEW = 'invoice-reminders-global-view',
  VIEW_TAB = 'view-tab',
  OPEN_MODAL = 'open-modal',
  CANCEL_MODAL = 'cancel-modal',
  MODAL_HELP = 'modal-help-click',
  ENABLE = 'enable-reminders',
  DISABLE = 'disable-reminders',
}
