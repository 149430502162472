import React from 'react'
import {
  AlertContext,
  AlertContextActions,
  AlertContextState,
} from './AlertContext'

export type AlertContextProviderProps = {
  readonly children?: React.ReactNode
}


export class AlertContextProvider extends React.Component<
    AlertContextProviderProps,
  AlertContextState
> {
  constructor(props: AlertContextProviderProps) {
    super(props)

    this.state = {
      type: null,
      message: null,
      show: false,
    }
  }

  public readonly render = () => {
    return (
      <AlertContext.Provider
        value={{
          state: this.state,
          actions: {
            onShow: this.onShow,
            onDismiss: this.onDismiss,
          },
        }}
      >
        {this.props.children}
      </AlertContext.Provider>
    )
  }

  private readonly onShow: AlertContextActions['onShow'] = (type, message) => {
    this.setState({
      type,
      message,
      show: true,
    })
  }

  private readonly onDismiss: AlertContextActions['onDismiss'] = () => {
    this.setState({
      type: null,
      message: null,
      show: false,
    })
  }
}
