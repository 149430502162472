import React from 'react';
import {Card, PageHead, Checkbox, Alert} from '@myob/myob-widgets'

const FeatureEnabledCard = (props) => {
    return (
        <Card header={<Card.Header child={<PageHead title={props.pageTitle}></PageHead>} />}>
            <div className={'customer-checkbox'}>
                { props.isEnabled &&
                    <Checkbox 
                        name={props.checkBoxName}
                        label={props.checkBoxLabel}
                        checked={props.checked}
                        onChange={props.onChange}
                        checkboxDisabled={props.disabled}
                    />
                }
            </div>
            { !props.isEnabled &&
                <Alert type="info" inline>
                    {props.alertText}
                </Alert>
            }
            {props.cardText}
        </Card>
    );
}

export default FeatureEnabledCard;