import * as url from 'url'
import get from 'lodash/get'
import React from 'react'
import UrlHelper from './helpers/UrlHelper'
import { createAuthTokenService } from './services/factories/createAuthTokenService'
import AuthenticationHelper from './helpers/AuthenticationHelper'
import { Spinner } from '@myob/myob-widgets';
import ls from 'local-storage'
import App from './App'

const LOCAL_STORAGE_KEY = 'initial-query-params'

class Auth extends React.Component {
  constructor(props) {
    super(props)

    this.query = UrlHelper.extractQueryParameters(
      url.parse(window.location.href).query
    )

    this.authTokenService = createAuthTokenService(
      process.env.REACT_APP_API_ENDPOINT
    )

    this.state = { waitingForToken: true }
  }

  componentDidMount = async () => {
    const urlWithoutQueryParameters = UrlHelper.uriWithoutQueryParameters(window.location.href)
    const accessToken = await AuthenticationHelper.obtainAuthToken(
      this.authTokenService,
      urlWithoutQueryParameters,
      window.external,
      get(this.query, 'code')
    )

    this.authToken = accessToken
    const redirected = get(this.query, 'code') !== undefined
    const needToRedirect = !redirected && this.authToken === undefined

    if (needToRedirect) {
      ls.set(LOCAL_STORAGE_KEY, this.query)
      window.location.href = AuthenticationHelper.buildLoginURI(
        process.env.REACT_APP_IDS_LOGIN_URI,
        process.env.REACT_APP_IDS_CLIENT_ID,
        urlWithoutQueryParameters,
        process.env.REACT_APP_IDS_RESOURCE
      )
      return
    }

    if (redirected) {
      this.query = ls.get(LOCAL_STORAGE_KEY)
    }

    this.setState({
      waitingForToken: false
    })
  }

  render() {
    return (this.state.waitingForToken ? <Spinner/> : <App authToken={this.authToken} query={this.query}/>)
  }
}
export default Auth
