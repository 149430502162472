import { Button } from '@myob/myob-widgets'
import ButtonRow from '@myob/myob-widgets/lib/components/ButtonRow/ButtonRow'
import Field from '@myob/myob-widgets/lib/components/Field/Field'
import Input from '@myob/myob-widgets/lib/components/Input/Input'
import Label from '@myob/myob-widgets/lib/components/Label/Label'
import Modal from '@myob/myob-widgets/lib/components/Modal/Modal'
import RadioButton from '@myob/myob-widgets/lib/components/RadioButton/RadioButton'
import {
  Placeholder,
  Schedule,
  ScheduleOffset,
  TemplateUtils,
} from '@myob/sme-cashin-reminders-domain/dist'
import { AllHtmlEntities } from 'html-entities'
import React from 'react'
import styled from 'styled-components'
import { ModalMode } from './enums/ModalMode'
import { Spacing } from './enums/Spacing'
import { TemplateEditor } from './TemplateEditor'
import { TemplateEditorInputType } from './TemplateEditorInput'

const StyledOffsetRadio = styled.div`
  display: flex;
  margin-right: 1.6rem
`

const StyledOffsetDay = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    margin-right: ${Spacing.MEDIUM};
  }

  input[type='text'] {
    width: 38px;
    text-align: center;
  }

  .label {
    padding-left: 5px !important;
  }

  .help-block {
    display: none;
  }
`

const StyledOffsetContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    padding: 0;
    margin: 0;
    margin-right: ${Spacing.LARGE};
  }

  .radio {
    margin-top: 0;
  }

  @media (max-width: 400px) {
    display: block;
    > div {
      margin-bottom: ${Spacing.MEDIUM};
    }
  }

  .form-group {
    margin-bottom: 0px;
  }
`

export type AsyncScheduleModel = {
  readonly numberOfDays: string
  readonly beforeOrAfterDueDate: string
  readonly subject: string
  readonly body: string
}

export type AsyncScheduleModalProps = {
  readonly mode: ModalMode
  readonly data: ReadonlyArray<Schedule>
  readonly id?: number
  readonly onUpdate: (schedule: Schedule, id?: number) => Promise<void>
  readonly onCancel: () => void
}

export type AsyncScheduleModalState = {
  readonly model: AsyncScheduleModel
  readonly errors: {
    readonly offset: string | undefined
    readonly subject: string | undefined
    readonly body: string | undefined
  }
}

export class AsyncScheduleModal extends React.Component<
  AsyncScheduleModalProps,
  AsyncScheduleModalState
> {
  private readonly defaultNumberOfDays = 1
  private readonly templatePlaceholders: ReadonlyArray<any> = [
    {
      value: Placeholder.CUSTOMER_NAME,
      displayName: 'Customer name',
    },
    { value: Placeholder.DUE_DATE, displayName: 'Due date' },
    {
      value: Placeholder.INVOICE_NUMBER,
      displayName: 'Invoice number',
    },
    {
      value: Placeholder.INVOICE_AMOUNT,
      displayName: 'Invoice amount',
    },
    { value: Placeholder.TRADING_NAME, displayName: 'My company name' },
  ]
  private readonly htmlEntities = new AllHtmlEntities()

  constructor(props: AsyncScheduleModalProps) {
    super(props)

    const schedule =
      props.id !== undefined && props.id !== null
        ? props.data.find(s => s.offset === props.id)
        : undefined
    const model = this.mapScheduleToModel(
      schedule || this.getDefaultSchedule(props.data)
    )
    const state = {
      model,
      errors: {
        offset: this.validateOffset(
          model.numberOfDays,
          model.beforeOrAfterDueDate,
          props
        ),
        subject: this.validateSubject(model.subject),
        body: this.validateSubject(model.body),
      },
    }
    this.state = state
  }

  public render(): React.ReactNode {
    // <Field/> is a undocumented Feelix component that is used by
    // various Feelix input-type components.
    // It generates the label component used by feelx.
    // The label used in Feelix inputs is styled differently
    // from Feelix <Label/> component. Thus <Field/> is used here
    // to maintain Feelix form and input style.
    return (
      <Modal title={this.renderTitle()} onCancel={this.props.onCancel}>
        <Modal.Body>
          <Field
            label="Send reminder"
            errorMessage={this.state.errors.offset}
            renderField={() => (
              <StyledOffsetContainer className={'offsetContainer'}>
                <StyledOffsetDay>
                  <Input
                    label="days"
                    hideLabel={true}
                    name="scheduleDay"
                    type="text"
                    value={this.state.model.numberOfDays}
                    // @ts-ignore
                    onChange={this.onNumberOfDaysChanged.bind(this)}
                  />
                  <Label size="default">
                    days
                  </Label>
                </StyledOffsetDay>
                <StyledOffsetRadio>
                  <RadioButton
                      checked={this.state.model.beforeOrAfterDueDate === 'before'}
                      label="Before due date"
                      value="before"
                      name="scheduleBeforeOrAfterDue"
                      // @ts-ignore
                      onChange={this.onBeforeOrAfterDueDateChanged.bind(this)}
                  />
                </StyledOffsetRadio>
                <RadioButton
                  checked={this.state.model.beforeOrAfterDueDate === 'after'}
                  label="After due date"
                  value="after"
                  name="scheduleBeforeOrAfterDue"
                  // @ts-ignore
                  onChange={this.onBeforeOrAfterDueDateChanged.bind(this)}
                />
              </StyledOffsetContainer>
            )}
          />
          <TemplateEditor
            inputs={[
              {
                onChange: this.onSubjectChanged,
                errorMessage: this.state.errors.subject,
                label: 'Subject',
                type: TemplateEditorInputType.Text,
                value: this.state.model.subject,
              },
              {
                onChange: this.onMessageChanged,
                errorMessage: this.state.errors.body,
                label: 'Message',
                type: TemplateEditorInputType.Textarea,
                value: this.state.model.body,
              },
            ]}
            placeholderStyle="square-brackets"
            placeholders={this.templatePlaceholders}
            container=".global-page"
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonRow>
            <Button
              type="secondary"
              className="btn-cancel"
              onClick={this.props.onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="btn-save"
              onClick={this.onUpdate}
              disabled={!this.isValid()}
            >
              Save
            </Button>
          </ButtonRow>
        </Modal.Footer>
      </Modal>
    )
  }

  private readonly calculateNextOffset = (
    data: ReadonlyArray<Schedule>
  ): number => {
    const offsets = data
      .filter(s => s.offset < 0)
      .map(s => Math.abs(s.offset))
      .sort()

    const nextOffset = Array.from(
      Array(Math.abs(ScheduleOffset.MIN)).keys()
    ).find(i => !offsets.includes(i) && i !== 0)
    return nextOffset ? -nextOffset : this.defaultNumberOfDays
  }

  private readonly getDefaultSchedule = (
    data: ReadonlyArray<Schedule>
  ): Schedule => {
    const offset = this.calculateNextOffset(data)
    return TemplateUtils.getDefaultSchedule(offset)
  }

  private readonly getOffset = (
    numberOfDays: string,
    beforeOrAfterDueDate: string
  ): number => {
    const i = beforeOrAfterDueDate === 'before' ? 1 : -1
    const days = parseInt(numberOfDays, 10)
    return days * i
  }

  private readonly isValid = () => {
    return (
      this.state.errors.offset === undefined &&
      this.state.errors.subject === undefined &&
      this.state.errors.body === undefined
    )
  }

  private readonly mapEditorToModel = (str: string) =>
    this.htmlEntities.encode(str.replace(/\u000A/g, '\n'))

  private readonly mapModelToEditor = (str: string) =>
    this.htmlEntities.decode(str)

  private readonly mapScheduleToModel = (
    schedule: Schedule
  ): AsyncScheduleModel => {
    return {
      numberOfDays: Math.abs(schedule.offset).toString(),
      beforeOrAfterDueDate: schedule.offset >= 0 ? 'before' : 'after',
      subject: this.mapModelToEditor(schedule.subject),
      body: this.mapModelToEditor(schedule.body),
    }
  }

  private readonly onNumberOfDaysChanged = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    this.setState({
      model: {
        ...this.state.model,
        numberOfDays: e.currentTarget.value,
      },
      errors: {
        ...this.state.errors,
        offset: this.validateOffset(
          e.currentTarget.value,
          this.state.model.beforeOrAfterDueDate,
          this.props
        ),
      },
    })
  }

  private readonly onBeforeOrAfterDueDateChanged = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    this.setState({
      model: {
        ...this.state.model,
        beforeOrAfterDueDate: e.currentTarget.value,
      },
      errors: {
        ...this.state.errors,
        offset: this.validateOffset(
          this.state.model.numberOfDays,
          e.currentTarget.value,
          this.props
        ),
      },
    })
  }

  private readonly onSubjectChanged = (value: string) => {
    this.setState({
      model: {
        ...this.state.model,
        subject: value,
      },
      errors: {
        ...this.state.errors,
        subject: this.validateSubject(value),
      },
    })
  }

  private readonly onMessageChanged = (value: string) => {
    this.setState({
      model: {
        ...this.state.model,
        body: value,
      },
      errors: {
        ...this.state.errors,
        body: this.validateBody(value),
      },
    })
  }

  private readonly onUpdate = async (): Promise<void> => {
    const schedule: Schedule = {
      offset: this.getOffset(
        this.state.model.numberOfDays,
        this.state.model.beforeOrAfterDueDate
      ),
      subject: this.mapEditorToModel(this.state.model.subject),
      body: this.mapEditorToModel(this.state.model.body),
    }
    await this.props.onUpdate(schedule, this.props.id)
  }

  private readonly renderTitle = () =>
    this.props.mode === ModalMode.ADD ? 'New reminder' : 'Edit reminder'

  private readonly validateRequired = (value?: string): string | undefined => {
    if (!value || value.trim() === '') {
      return 'This field is required.'
    }
    return undefined
  }

  private readonly validateMaxLength = (maxLength: number, value?: string) => {
    if (value && value.length > maxLength) {
      return `Character limit exceeded by ${value.length -
        maxLength} - please shorten your message.`
    }
    return undefined
  }

  private readonly validateOffset = (
    numberOfDays: string,
    beforeOrAfterDueDate: string,
    props: AsyncScheduleModalProps
  ): string | undefined => {
    const requiredError = this.validateRequired(numberOfDays)
    if (requiredError) {
      return requiredError
    }

    // Number of days must always be positive number
    const days = parseInt(numberOfDays, 10)
    if (isNaN(days) || days < 0) {
      return 'Please enter a positive number.'
    }

    // Range: Max
    const offset = this.getOffset(numberOfDays, beforeOrAfterDueDate)
    if (offset > ScheduleOffset.MAX) {
      return `Reminders cannot be scheduled more than ${
        ScheduleOffset.MAX
      } days before the due date.`
    }

    // Range: Min
    if (offset < ScheduleOffset.MIN) {
      return `Reminders cannot be scheduled more than ${Math.abs(
        ScheduleOffset.MIN
      )} days after the due date.`
    }

    // Date offset must be unique
    const filtered = props.data.filter(
      s => s.offset !== props.id && s.offset === offset
    )
    if (filtered.length) {
      return 'You’ve already created this reminder.'
    }
    return undefined
  }

  private readonly validateSubject = (subject: string): string | undefined => {
    const requiredError = this.validateRequired(subject)
    if (requiredError) {
      return requiredError
    }

    const maxLengthError = this.validateMaxLength(255, subject)
    if (maxLengthError) {
      return maxLengthError
    }

    return undefined
  }

  private readonly validateBody = (body: string): string | undefined => {
    const value = body.replace(/\n/g, '')
    const requiredError = this.validateRequired(value)
    if (requiredError) {
      return requiredError
    }

    const maxLengthError = this.validateMaxLength(4000, value)
    if (maxLengthError) {
      return maxLengthError
    }

    return undefined
  }
}
