import React from 'react'
import { AlertText } from './AlertText'

export const AlertTextMap: { [key in AlertText]: React.ReactNode } = {
  [AlertText.ERROR_GLOBAL_INIT]: 'Unable to get invoice reminders settings.',
  [AlertText.ERROR_CUSTOMER_INIT]: 'Unable to get invoice reminders settings.',

  [AlertText.BUSINESS_CUSTOMER_SEND_DAILY]:
    "You've turned invoice reminders on for this customer.",
  [AlertText.BUSINESS_CUSTOMER_NOT_SEND_DAILY]:
    "You've turned invoice reminders off for this customer.",
  [AlertText.BUSINESS_CUSTOMER_DAILY_ERROR]:
    'Unable to change invoice reminders settings for this customer, please try again.',

  [AlertText.BUSINESS_CUSTOMER_SEND_MONTHLY]:
    "You've turned invoice summaries on for this customer.",
  [AlertText.BUSINESS_CUSTOMER_NOT_SEND_MONTHLY]:
    "You've turned unpaid invoice summaries off for this customer.",
  [AlertText.BUSINESS_CUSTOMER_MONTHLY_ERROR]:
    'Unable to change invoice summary settings for this customer, please try again.',

  [AlertText.BUSINESS_PREFERENCES_DAILY_ENABLED_UPDATE_FALSE]:
    'Invoice reminders turned off.',
  [AlertText.BUSINESS_PREFERENCES_DAILY_ENABLED_UPDATE_TRUE]:
    'Invoice reminders turned on.',
  [AlertText.BUSINESS_PREFERENCES_DAILY_ENABLED_UPDATE_ERROR]:
    'Unable to change invoice reminders settings, please try again.',
  [AlertText.BUSINESS_PREFERENCES_DAILY_ALGORITHM_UPDATE_WHITELISTED]:
    'Invoice reminders turned on for specific customers! Choose the customers by opening their details and going to invoice reminder settings.',
  [AlertText.BUSINESS_PREFERENCES_DAILY_ALGORITHM_UPDATE_BLACKLISTED]:
    'Invoice reminders turned on. Reminders will be sent according to the schedule below.',
  [AlertText.BUSINESS_PREFERENCES_DAILY_ALGORITHM_UPDATE_ERROR]:
    'Unable to change invoice reminders settings, please try again.',
  [AlertText.BUSINESS_PREFERENCES_DAILY_SCHEDULE_DELETE]:
    'Your reminder has been deleted.',
  [AlertText.BUSINESS_PREFERENCES_DAILY_SCHEDULE_DELETE_ERROR]:
    "We couldn't delete your reminder. Please try again.",
  [AlertText.BUSINESS_PREFERENCES_DAILY_SCHEDULE_UPDATE]:
    'Your reminder has been scheduled.',
  [AlertText.BUSINESS_PREFERENCES_DAILY_SCHEDULE_UPDATE_ERROR]:
    "We couldn't save your reminder. Please try again.",

  [AlertText.BUSINESS_PREFERENCES_MONTHLY_ENABLED_UPDATE_FALSE]:
    'Invoice summaries turned off.',
  [AlertText.BUSINESS_PREFERENCES_MONTHLY_ENABLED_UPDATE_TRUE]:
    'Invoice summaries turned on.',
  [AlertText.BUSINESS_PREFERENCES_MONTHLY_ENABLED_UPDATE_ERROR]:
    'Unable to change invoice summary settings, please try again.',
  [AlertText.BUSINESS_PREFERENCES_MONTHLY_ALGORITHM_UPDATE_WHITELISTED]:
    'Invoice summaries turned on for specific customers! Choose the customers by opening their details and going to invoice reminder settings.',
  [AlertText.BUSINESS_PREFERENCES_MONTHLY_ALGORITHM_UPDATE_BLACKLISTED]:
    'Invoice summaries turned on. Customers will be emailed a list of their unpaid invoices on the 3rd of every month.',
  [AlertText.BUSINESS_PREFERENCES_MONTHLY_ALGORITHM_UPDATE_ERROR]:
    'Unable to change invoice summary settings, please try again.',
}
