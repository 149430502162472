export enum RoutePath {
  COMMANDCENTRE = '/preferences',
  CUSTOMER = '/preferences/customer',
  CUSTOMER_ERROR = '/preferences/customer/error',
  DASHBOARD = '/preferences',
  EMAILASSISTANTSETUPWIZARD = '/preferences',
  ERROR = '/error',
  GLOBAL = '/preferences',
  ONLINEINVOICESETUPWIZARD = '/preferences',
}
