import React from 'react'

export const DebugInformation = props => {
  return (
    <div className={props.className}>
      <h1>DEBUG</h1>
      <table>
        <thead>
          <tr>
            <th>key</th>
            <th>value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>AuthToken</td>
            <td>
              {window.external &&
              window.external.ArlBrowserFormGetAuthTokenCallback
                ? window.external.ArlBrowserFormGetAuthTokenCallback()
                : 'No externals'}
            </td>
          </tr>
          <tr>
            <td>URL</td>
            <td>{window.location.href}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
