import { Consumer } from '@myob/sme-cashin-reminders-domain'
import React from 'react'

export type ConsumerContextState = {
  readonly consumer: Consumer
  readonly children?: React.ReactNode
}

export const ConsumerContext = React.createContext<{
  readonly state: ConsumerContextState
}>({
  state: {
    consumer: Consumer.ACCOUNT_RIGHT_LIVE,
  },
})
