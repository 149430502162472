import {
  BusinessPreferences,
  Consumer,
  Region,
} from '@myob/sme-cashin-reminders-domain'
import { AxiosInstance } from 'axios'
import uuid from 'uuid'
import { BusinessPreferencesNotFoundError } from './errors/BusinessPreferencesNotFoundError'
import { is404 } from './helpers/is404'
import { IBusinessPreferencesService } from './interfaces/IBusinessPreferencesService'
import { PartialBusinessPreferences } from './models/PartialBusinessPreferences'

export class BusinessPreferencesService implements IBusinessPreferencesService {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly businessId: BusinessPreferences['businessId'],
    private readonly consumer: BusinessPreferences['consumer'],
    private readonly region: Region
  ) {}

  public readonly get = async (): Promise<PartialBusinessPreferences> => {
    try {
      const response = await this.axios.request({
        method: 'GET',
        url: `/v1/preferences/${this.businessId}`,
        headers: { 'x-myobapi-requestid': uuid.v4() },
      })

      return {
        dailyEnabled: response.data.dailyEnabled,
        dailyAlgorithm: response.data.dailyAlgorithm,
        dailySchedule: response.data.dailySchedule,
        monthlyEnabled: response.data.monthlyEnabled,
        monthlyAlgorithm: response.data.monthlyAlgorithm,
      }
    } catch (e) {
      if (is404(e)) {
        throw new BusinessPreferencesNotFoundError(this.businessId)
      }

      throw e
    }
  }

  public readonly update = async (
    updatedPreferences: PartialBusinessPreferences
  ) => {
    await this.axios.request({
      method: 'PUT',
      url: `/v1/preferences/${this.businessId}`,
      headers: { 'x-myobapi-requestid': uuid.v4() },
      data: {
        consumer: this.consumer,
        region: this.consumer === Consumer.ESSENTIALS ? this.region : undefined,
        dailyEnabled: updatedPreferences.dailyEnabled,
        dailyAlgorithm: updatedPreferences.dailyAlgorithm,
        dailySchedule: updatedPreferences.dailySchedule,
        monthlyEnabled: updatedPreferences.monthlyEnabled,
        monthlyAlgorithm: updatedPreferences.monthlyAlgorithm,
      },
    })
  }
}
