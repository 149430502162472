import React from 'react'
import styled from 'styled-components'
import { ConsumerRenderer } from '../widgets/ConsumerRenderer'
import { DailyHelpLink } from '../widgets/enums/HelpLink'
import { Spacing } from '../widgets/enums/Spacing'
import { Heading } from '../widgets/Heading'
import { FeedbackLink } from './FeedbackLink'
import { HelpLink } from './HelpLink'

export type HeaderProps = {
    readonly className?: string
    readonly text?: string
    readonly isSmep?: boolean
}

const StyledHeading = styled(Heading)`
  margin: 0;
`
const StyledActionsContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;

  & > * {
    margin-right: ${Spacing.MEDIUM};
    margin-bottom: 24px !important;

    &:last-child {
      margin-right: 0;
    }
  }
`
const StyledContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`

export const Header: React.FunctionComponent<HeaderProps> = props => {
  return (
    <StyledContainer className={props.className}>
      <StyledHeading level={1}>
        {props.text ? props.text : 'Reminders'}
      </StyledHeading>
      <StyledActionsContainer>
        <FeedbackLink />
        <ConsumerRenderer
          ARL={
            <HelpLink
              url={props.isSmep ? DailyHelpLink.SMEP : DailyHelpLink.ARL}
            />
          }
          Essentials={<HelpLink url={DailyHelpLink.EA} />}
        />
      </StyledActionsContainer>
    </StyledContainer>
  )
}
