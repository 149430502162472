import React from 'react';
import { Card, PageHead, Checkbox, RadioButton } from '@myob/myob-widgets'
import { AsyncScheduleTable } from '../../widgets/AsyncScheduleTable'
import styles from './TabbedPreferences.css';
import { ConsumerRenderer } from '../../widgets/ConsumerRenderer'
import { DailyHelpLink } from '../../widgets/enums/HelpLink'

export class DailyPreferences extends React.Component {
    checkToShowModal = () => {
        this.props.checkToShowModal(this.props.businessPreferences.dailyEnabled, this.props.onUpdateDailyEnabled)
    }
    render = () => {
        return (
            <div>
                <Card header={<Card.Header child={<PageHead title="Enable invoice reminders"></PageHead>} />}>
                    <p>Automatically send emails to your customers when their invoice is due. You can choose the customers and customise the emails below.</p>
                    <div className={'reminders-checkbox'}>
                        <Checkbox
                            name={'dailyEnabledCheckbox'}
                            label={'Send invoice reminders'}
                            checked={this.props.businessPreferences.dailyEnabled}
                            onChange={this.checkToShowModal}
                            disabled={this.props.locked}
                        />
                    </div>
                </Card>
                <Card header={<Card.Header child={<PageHead title="Select your customers"></PageHead>} />}>
                    <p>Choose which customers to remind.</p>
                    <div className={'reminders-radio'}>
                        <RadioButton
                            checked={this.props.businessPreferences.dailyAlgorithm === 'blacklisted'}
                            name={'blacklisted'}
                            key={'blacklisted'}
                            value={'blacklisted'}
                            label={
                                <div>
                                    <p className='algorithm-label'>Send to all customers</p>
                                    <p className='algorithm-description'>Reminders will be sent to all customers.</p>
                                    <br/>
                                </div>
                            }
                            onChange={this.props.onUpdateDailyAlgorithm}
                        />
                        <RadioButton
                            checked={this.props.businessPreferences.dailyAlgorithm === 'whitelisted'}
                            name={'whitelisted'}
                            key={'whitelisted'}
                            value={'whitelisted'}
                            label={
                                <div>
                                    <p className='algorithm-label'>Send to specific customers</p>
                                    <p className='algorithm-description'>Reminders will be sent to customers that you choose. Open each customer’s details and go to their invoice reminder settings.</p>
                                    <p className='algorithm-description'>
                                        <ConsumerRenderer
                                            ARL ={
                                                <a
                                                    href={this.props.isSmep ? DailyHelpLink.SMEP : DailyHelpLink.ARL}
                                                    target="_blank" rel="noopener noreferrer"
                                                >
                                                    Learn how
                                                </a> 
                                            }
                                            Essentials={
                                                <a
                                                    href={DailyHelpLink.EA}
                                                    target="_blank" rel="noopener noreferrer"
                                                >
                                                    Learn how
                                                </a>
                                            }
                                        />
                                    </p>
                                </div>
                            }
                            onChange={this.props.onUpdateDailyAlgorithm}/>
                    </div>
                </Card>
                <Card header={<Card.Header child={<PageHead title="Customise email message and schedule"></PageHead>} />}>
                    <p>Choose when to send out reminders and customise your email text. You can have a maximum of 5 reminders active at the same time.</p>
                    <AsyncScheduleTable
                        data={this.props.businessPreferences.dailySchedule}
                        onUpdate={this.props.onUpdateDailySchedule}
                        onDelete={this.props.onDeleteDailySchedule}
                        disabled={this.props.locked}
                    />
                </Card>
            </div>
        )
    }
}
