import { BusinessCustomer } from '@myob/sme-cashin-reminders-domain'
import { BaseError } from '../../domain/errors/BaseError'

export class BusinessCustomerPreferencesNotFoundError extends BaseError {
  constructor(
    businessId: BusinessCustomer['businessId'],
    customerId: BusinessCustomer['customerId']
  ) {
    super(
      `BusinessCustomerPreferences not found for business with id ${businessId} and customer with id ${customerId}`
    )
  }
}
