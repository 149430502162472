import { BusinessCustomer } from '@myob/sme-cashin-reminders-domain'
import axios from 'axios'
import { BusinessCustomerPreferencesService } from '../BusinessCustomerPreferencesService'
import { IBusinessCustomerPreferencesService } from './../interfaces/IBusinessCustomerPreferencesService'

export const createBusinessCustomerPreferencesService = (
  url: string,
  authToken: string,
  businessId: BusinessCustomer['businessId'],
  customerId: BusinessCustomer['customerId']
): IBusinessCustomerPreferencesService => {
  return new BusinessCustomerPreferencesService(
    axios.create({
      baseURL: url,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    }),
    businessId,
    customerId
  )
}
