import React from 'react';
import { Tabs } from '@myob/myob-widgets'

export class TabbedNav extends React.Component {

    render = () => {
        return (
            <Tabs
                items ={[{id: 'daily', label: 'Reminders'}, {id: 'monthly', label: 'Unpaid invoice summaries'}]}
                selected={this.props.activeTab}
                onSelected={(selected) => this.props.changeTab({selected})}
            />
        )
    }
}
