export enum AnalyticsSource {
  COMMANDCENTRE = 'sales',
  CUSTOMER = 'customer',
  CUSTOMER_ERROR = 'customer-error',
  DASHBOARD = 'dashboard',
  EMAILASSISTANTSETUPWIZARD = 'email-wizard',
  ERROR = 'error',
  GLOBAL = 'settings',
  ONLINEINVOICESETUPWIZARD = 'online-payment-wizard',
}
