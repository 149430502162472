import React from 'react'
import { SplashPage } from '../SplashPage/SplashPage'
import { ConsumerRenderer } from '../widgets/ConsumerRenderer'
import { TextBlock } from '../widgets/TextBlock'
import { ErrorSplashImage } from './ErrorSplashImage'

export type CustomerErrorPageProps = {
  readonly className?: string
  readonly isSmep?: boolean
}

export const CustomerErrorPage: React.FunctionComponent<
  CustomerErrorPageProps
> = props => {
  const smepDescription =
    'Save time chasing late payments by automating your reminders.'
  const description =
    'Enabling invoice reminders will automatically remind your customers to pay their outstanding invoices.'

  const arlDirection = (
    <span>
      Reminders can be enabled via the <em>Emailing</em> tab in{' '}
      <em>Preferences</em>.
    </span>
  )

  const eaDirection = (
    <span>
      Reminders can be enabled via the <em>Reminders</em> tab in{' '}
      <em>Invoice and Quote Settings</em>.
    </span>
  )

  const smepDirection = (
    <span>
      To turn on reminders, click on your business name and go to{' '}
      <em>Sales settings</em> {'>'} <em>Reminders</em>.
    </span>
  )

  return (
    <SplashPage
      className={props.className}
      title={'Invoice reminders are turned off'}
      splashImage={<ErrorSplashImage />}
    >
      <TextBlock>
        <ConsumerRenderer
          ARL={props.isSmep ? smepDescription : arlDirection}
          Essentials={eaDirection}
        />
      </TextBlock>
      <TextBlock>{props.isSmep ? smepDirection : description}</TextBlock>
    </SplashPage>
  )
}
