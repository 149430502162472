import { CommentsIcon } from '@myob/myob-widgets'
import React from 'react'
import { FeedbackContext } from '../../contexts/FeedbackContext'
import { IconLink } from '../widgets/IconLink'

export type FeedbackButtonProps = {
  readonly className?: string
}

export const FeedbackLink: React.FunctionComponent<
  FeedbackButtonProps
> = props => {
  return (
    <FeedbackContext.Consumer>
      {context => (
        <IconLink
          className={props.className}
          href={undefined}
          onClick={context.actions.onFeedback}
          icon={<CommentsIcon title="feedback" />}
        >
          Send Feedback
        </IconLink>
      )}
    </FeedbackContext.Consumer>
  )
}
