import Button from '@myob/myob-widgets/lib/components/Button/Button'
import ButtonRow from '@myob/myob-widgets/lib/components/ButtonRow/ButtonRow'
import Modal from '@myob/myob-widgets/lib/components/Modal/Modal'
import React from 'react'
import styled from 'styled-components'
import { TextBlock } from './TextBlock'

const StyledContainer = styled.div`
  text-align: center;
`

export type AsyncScheduleDeleteModalProps = {
  readonly onCancel: () => void
  readonly onDelete: () => Promise<void>
}

export const AsyncScheduleDeleteModal: React.FunctionComponent<
  AsyncScheduleDeleteModalProps
> = props => {
  return (
    <Modal title="Delete reminder?" onCancel={props.onCancel}>
      <Modal.Body>
        <StyledContainer>
          <TextBlock>Just checking that you're sure about this.</TextBlock>
        </StyledContainer>
      </Modal.Body>
      <Modal.Footer>
        <ButtonRow>
          <Button type="secondary" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button type="delete" onClick={props.onDelete}>
            Delete reminder
          </Button>
        </ButtonRow>
      </Modal.Footer>
    </Modal>
  )
}
