import {
  flxPaletteStorm100,
  flxPaletteStorm17,
  flxPaletteStorm72,
} from '@myob/myob-styles/dist/design-tokens/js/design-tokens'

export const Palette = {
  INACTIVE: flxPaletteStorm17,
  STANDARD: flxPaletteStorm72,
  DARK: flxPaletteStorm100,
}
