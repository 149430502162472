import { BusinessCustomer } from '@myob/sme-cashin-reminders-domain'
import { AxiosInstance } from 'axios'
import uuid from 'uuid'
import { BusinessCustomerPreferencesNotFoundError } from './errors/BusinessCustomerNotFoundError'
import { is404 } from './helpers/is404'
import { IBusinessCustomerPreferencesService } from './interfaces/IBusinessCustomerPreferencesService'

export class BusinessCustomerPreferencesService
  implements IBusinessCustomerPreferencesService {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly businessId: BusinessCustomer['businessId'],
    private readonly customerId: BusinessCustomer['customerId']
  ) {}

  public readonly get = async (): Promise<BusinessCustomer> => {
    try {
      const response = await this.axios.request({
        method: 'GET',
        url: `/v1/preferences/${this.businessId}/customers/${this.customerId}`,
        headers: { 'x-myobapi-requestid': uuid.v4() },
      })

      return {
        businessId: this.businessId,
        customerId: this.customerId,
        dailyStatus: response.data.dailyStatus,
        monthlyStatus: response.data.monthlyStatus,
      }
    } catch (e) {
      if (is404(e)) {
        throw new BusinessCustomerPreferencesNotFoundError(
          this.businessId,
          this.customerId
        )
      }

      throw e
    }
  }

  public readonly update = async (updatedPreferences: {
    readonly dailyStatus: BusinessCustomer['dailyStatus']
    readonly monthlyStatus: BusinessCustomer['monthlyStatus']
  }) => {
    await this.axios.request({
      method: 'PUT',
      url: `/v1/preferences/${this.businessId}/customers/${this.customerId}`,
      headers: { 'x-myobapi-requestid': uuid.v4() },
      data: {
        dailyStatus: updatedPreferences.dailyStatus,
        monthlyStatus: updatedPreferences.monthlyStatus,
      },
    })
  }
}
