import React from 'react'

export type FeedbackContextState = {}

export type FeedbackContextActions = {
  readonly onFeedback: () => void
}

export const FeedbackContext = React.createContext<{
  readonly state: FeedbackContextState
  readonly actions: FeedbackContextActions
}>({
  state: {},
  actions: {
    onFeedback: () => undefined,
  },
})
