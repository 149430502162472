import Field from '@myob/myob-widgets/lib/components/Field/Field'
import classNames from 'classnames'
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  convertFromRaw,
  DraftBlockType,
  DraftEntityMutability,
  DraftHandleValue,
  Editor,
  EditorChangeType,
  EditorState,
  Modifier,
  RawDraftEntityRange,
  SelectionState,
} from 'draft-js'
import React from 'react'
import styled from 'styled-components'
import { TemplateEditorDecorator } from './TemplateEditorDecorator'

const StyledContainer = styled.div`
  .form-group {
    height: auto;
  }
  
  .form-control {
    border-radius: 4px;
    border-style: solid;
    border-color: #c5c9ce;
    border-width: 1px;
    padding: 0.4rem 1rem;
  }
  
  .rich-text-editor-box {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    word-break: break-word;
    overflow-x: auto;
    overflow-y: auto;

    .label {
      font-size: 13px;
    }

    .public-DraftEditor-content {
      height: 225px;
      max-height: 225px;
    }

    &.rich-text-editor-single {
      max-height: 30px;
      min-height: 30px;

      .DraftEditor-editorContainer,
      .public-DraftEditor-content {
        max-height: 20px;
      }

      .DraftEditor-root {
        height: auto;
      }
    }
  }
`
export enum TemplateEditorInputType {
  Text,
  Textarea,
}

export type TemplateEditorInputData = {
  readonly onChange: (value: string) => void
  readonly errorMessage?: string
  readonly type: TemplateEditorInputType
  readonly key?: number
  readonly label?: string
  readonly value?: string
}

export type TemplateEditorInputProps = {
  readonly data: TemplateEditorInputData
  readonly onFocus: (component: TemplateEditorInput) => void
  readonly pattern?: string
}

export type TemplateEditorInputState = {
  readonly editorState: EditorState
}

export class TemplateEditorInput extends React.Component<
  TemplateEditorInputProps,
  TemplateEditorInputState
> {
  private readonly decorators: CompositeDecorator | undefined
  private readonly placeholderEntityType: string = 'PLACEHOLDER'
  private readonly placeholderEntityMutability: DraftEntityMutability =
    'IMMUTABLE'

  constructor(props: TemplateEditorInputProps) {
    super(props)

    this.decorators = props.pattern
      ? new CompositeDecorator([
          {
            strategy: this.compositeDecoratorStrategy,
            component: this.compositeDecoratorComponent,
          },
        ])
      : undefined

    this.state = {
      editorState: props.data.value
        ? this.createEditorWithContent(props)
        : EditorState.createEmpty(this.decorators),
    }
  }

  public componentDidMount(): void {
    // Trigger onChange to update value after it was converted to editor
    this.notifyOnChange(this.state.editorState)
  }

  public readonly insertPlaceholder = (placeholder: string): void => {
    const editorState = this.state.editorState
    const currentContent = editorState.getCurrentContent()
    const selection = editorState.getSelection()
    const contentState = this.insertEntity(
      currentContent,
      selection,
      placeholder
    )
    this.setState({
      editorState: this.updateEditorState(
        editorState,
        contentState,
        'insert-characters'
      ),
    })
  }

  public render(): React.ReactNode {
    return (
      <Field
        label={this.props.data.label || 'Hidden'}
        hideLabel={this.props.data.label === undefined}
        errorMessage={this.props.data.errorMessage}
        renderField={() => (
          <StyledContainer>
            <div
              className={classNames(
                'form-control rich-text-editor-box RichTextEditor-editor RichTextEditor',
                this.props.data.type === TemplateEditorInputType.Text
                  ? 'rich-text-editor-single'
                  : undefined
              )}
            >
              <Editor
                editorState={this.state.editorState}
                onChange={this.onChange}
                handlePastedText={
                  this.props.data.type === TemplateEditorInputType.Text
                    ? this.handlePastedText
                    : undefined
                }
                handleReturn={
                  this.props.data.type === TemplateEditorInputType.Text
                    ? this.handleReturn
                    : undefined
                }
                onFocus={() => this.props.onFocus(this)}
                spellCheck={true}
                stripPastedStyles={true}
              />
            </div>
          </StyledContainer>
        )}
      />
    )
  }

  private readonly attachEntitiesToEditorState = (
    editorState: EditorState,
    regex: RegExp
  ): EditorState => {
    const currentContentState = editorState.getCurrentContent()
    const blockMap = currentContentState.getBlockMap()
    const contentState = blockMap.reduce(
      (accumulator, contentBlock?: ContentBlock) =>
        this.attachEntitiesToContentState(accumulator!, contentBlock!, regex),
      currentContentState
    )

    if (!contentState.equals(currentContentState)) {
      return this.updateEditorState(editorState, contentState, 'apply-entity')
    }

    return editorState
  }

  private readonly attachEntitiesToContentState = (
    contentState: ContentState,
    contentBlock: ContentBlock,
    regex: RegExp
  ): ContentState => {
    const plainText = contentBlock.getText()
    const matches = this.execAll(plainText, regex)
    return matches.reduce(
      (accumulator: ContentState, match) =>
        this.attachEntity(
          accumulator,
          contentBlock,
          plainText,
          match.index,
          match.index + match.length
        ),
      contentState
    )
  }

  private readonly attachEntity = (
    contentState: ContentState,
    contentBlock: ContentBlock,
    plainText: string,
    start: number,
    end: number
  ): ContentState => {
    const existingEntityKey = contentBlock.getEntityAt(start)
    if (existingEntityKey) {
      // Avoid manipulation in case the placeholder already has an entity
      const entity = contentState.getEntity(existingEntityKey)
      if (entity && entity.getType() === this.placeholderEntityType) {
        return contentState
      }
    }

    const blockKey = contentBlock.getKey()
    const selection = new SelectionState({
      anchorKey: blockKey,
      anchorOffset: start,
      focusKey: blockKey,
      focusOffset: end,
    })

    const placeholder = plainText.substring(start, end)
    return this.insertEntity(contentState, selection, placeholder)
  }

  private readonly compositeDecoratorStrategy = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    currentContent: ContentState
  ): void => {
    if (this.props.pattern) {
      const regex = new RegExp(this.props.pattern, 'g')
      contentBlock.getText().replace(
        regex,
        (match: string, offset: number): any => {
          callback(offset, offset + match.length)
        }
      )
    }
  }

  private readonly compositeDecoratorComponent = (props: any): JSX.Element => {
    return <TemplateEditorDecorator value={props.children} />
  }

  private readonly createEditorWithContent = (
    props: TemplateEditorInputProps
  ): EditorState => {
    const entityKey = 0
    const plainText = props.data.value || ''
    const blocks = plainText.split('\\n').map((str, index) => {
      const entityRanges = props.pattern
        ? (this.getEntityRanges(
            str,
            props.pattern,
            entityKey
            // tslint:disable-next-line:readonly-array
          ) as RawDraftEntityRange[])
        : []
      return {
        key: index.toString(),
        text: str,
        type: 'unstyled' as DraftBlockType,
        inlineStyleRanges: [],
        entityRanges,
        depth: 0,
      }
    })
    const rawContent = {
      blocks,
      entityMap: {
        [entityKey.toString()]: {
          type: this.placeholderEntityType,
          mutability: this.placeholderEntityMutability as DraftEntityMutability,
          data: {},
        },
      },
    }
    const contentState = convertFromRaw(rawContent)
    return EditorState.createWithContent(contentState, this.decorators)
  }

  private readonly execAll = (plainText: string, regex: RegExp) => {
    // tslint:disable-next-line:no-let readonly-array
    const matches: any  = []
    // tslint:disable-next-line:no-let
    let match
    // tslint:disable-next-line:no-conditional-assignment
    while ((match = regex.exec(plainText)) !== null) {
      const entity = {
        index: match.index,
        length: match[0].length,
      }
      matches.push(entity)
    }
    return matches
  }

  private readonly getEntityRanges = (
    plainText: string,
    pattern: string,
    entityKey: number
  ): ReadonlyArray<RawDraftEntityRange> => {
    const regex = new RegExp(pattern, 'g')
    const matches = this.execAll(plainText, regex)
    return matches.map(match => ({
      offset: match.index,
      length: match.length,
      key: entityKey,
    }))
  }

  private readonly handlePastedText = (
    text: string,
    html: string | undefined,
    editorState: EditorState
  ): DraftHandleValue => {
    const currentContent = editorState.getCurrentContent()
    const selection = editorState.getSelection()
    const contentState = Modifier.replaceText(
      currentContent,
      selection,
      text.replace(/\n/g, ' ')
    )

    this.setState({
      editorState: EditorState.push(
        editorState,
        contentState,
        'insert-characters'
      ),
    })
    return 'handled'
  }

  private readonly handleReturn = (
    e: any,
    editorState: EditorState
  ): DraftHandleValue => {
    // Prevent creating next-line for text input (single-line)
    return 'handled'
  }

  private readonly insertEntity = (
    contentState: ContentState,
    selection: SelectionState,
    text: string
  ): ContentState => {
    const entityKey = contentState
      .createEntity(
        this.placeholderEntityType,
        this.placeholderEntityMutability
      )
      .getLastCreatedEntityKey()
    return selection.isCollapsed()
      ? Modifier.insertText(contentState, selection, text, undefined, entityKey)
      : Modifier.replaceText(
          contentState,
          selection,
          text,
          undefined,
          entityKey
        )
  }

  private readonly notifyOnChange = (editorState: EditorState): void => {
    if (this.props.data.onChange) {
      const currentContent = editorState.getCurrentContent()
      const value = currentContent.getPlainText()
      this.props.data.onChange(value)
    }
  }

  private readonly onChange = (editorState: EditorState): void => {
    if (this.props.pattern) {
      // Avoid unnecessary manipulation in case there is no placeholder pattern
      const regex = new RegExp(this.props.pattern, 'g')
      const editorStateWithEntities = this.attachEntitiesToEditorState(
        editorState,
        regex
      )
      this.setState({ editorState: editorStateWithEntities })
    } else {
      this.setState({ editorState })
    }
    this.notifyOnChange(editorState)
  }

  private readonly updateEditorState = (
    editorState: EditorState,
    contentState: ContentState,
    changeType: EditorChangeType
  ): EditorState => {
    const newEditorState = EditorState.push(
      editorState,
      contentState,
      changeType
    )
    return EditorState.forceSelection(
      newEditorState,
      newEditorState.getSelection()
    )
  }
}
