import React from 'react';
import { FormTemplate, Navigation} from '@myob/myob-widgets'
import { AlertContext } from '../../../contexts/AlertContext'
import { Alert } from '../../widgets/Alert'
import { AlertTextMap } from '../../widgets/enums/AlertTextMap'
import { Header } from '../Header'
import MYOBLogo from '@myob/myob-widgets/lib/components/MYOBLogo/MYOBLogo'
import { ConsumerRenderer } from '../../widgets/ConsumerRenderer'
import FeatureEnabledCard from './FeatureEnabledCard'
import styles from './CustomerPreferences.css';
import { DailyHelpLink, MonthlyHelpLink } from '../../widgets/enums/HelpLink'

export class CustomerPreferencePage extends React.Component {

    render = () => {
        const alert =
            <AlertContext.Consumer>
                {context => {
                    if (context.state.show) {
                        return (
                            <Alert
                                type={context.state.type}
                                onDismiss={context.actions.onDismiss}
                            >
                                {AlertTextMap[context.state.message]}
                            </Alert>
                        )
                    }

                    return null
                }}
            </AlertContext.Consumer>
        return (
            <div
                style={{
                    background: '#ebeef1',
                    height: '100%',
                    width: '100%',
                }}
            >
                <ConsumerRenderer
                    ARL={null}
                    Essentials={
                        <div className={'header-bar'}>
                            <Navigation
                                brand={
                                    <Navigation.Brand url={'#'} width={'73px'}>
                                        <MYOBLogo type={'gradient'} />
                                    </Navigation.Brand>
                                }
                                primary={[]}
                                secondary={[]}
                            />
                        </div>
                    }
                />
                <FormTemplate alert={alert} pageHead={<Header text={'Reminders and Unpaid invoice summaries'} isSmep={this.props.isSmep} />}>
                    <p>Manage settings for this individual customer.</p>
                    <FeatureEnabledCard
                        pageTitle = 'Reminders'
                        isEnabled = {this.props.businessPreferences.dailyEnabled}
                        checked = {this.props.sendDaily}
                        onChange = {this.props.onUpdateSendDaily}
                        checkboxDisabled={this.props.locked || !this.props.businessPreferences.dailyEnabled}
                        checkBoxName={'SendDailyCheckbox'}
                        checkBoxLabel={'Send invoice reminders to this customer'}
                        alertText = {(
                            <p>To use Reminders, go to your settings and turn them on.
                                {
                                    <ConsumerRenderer
                                        ARL={
                                            <a href={this.props.isSmep ? DailyHelpLink.SMEP : DailyHelpLink.ARL}
                                                target="_blank" rel="noopener noreferrer">Learn how</a>
                                        }
                                        Essentials={
                                            <a href={DailyHelpLink.EA}
                                                target="_blank" rel="noopener noreferrer">Learn how</a>
                                        }
                                    />
                                } </p>
                        )}
                        cardText = {(
                            <div>
                                <p className={'no-bottom-margin'}>To see how often reminders are sent, and to customise reminders, check your general invoice reminders settings.</p>
                            </div>
                        )}
                    />
                    <FeatureEnabledCard
                        pageTitle = 'Unpaid invoice summaries'
                        isEnabled = {this.props.businessPreferences.monthlyEnabled}
                        checked = {this.props.sendMonthly}
                        onChange = {this.props.onUpdateSendMonthly}
                        checkboxDisabled={this.props.locked || !this.props.businessPreferences.dailyEnabled}
                        checkBoxName={'SendMonthlyCheckbox'}
                        checkBoxLabel={'Send unpaid invoice summaries to this customer'}
                        alertText = {(
                            <p>To use Unpaid invoice summaries, go to your settings and turn them on.
                                {
                                    <ConsumerRenderer
                                        ARL={
                                            <a href={this.props.isSmep ? MonthlyHelpLink.SMEP : MonthlyHelpLink.ARL}
                                                target="_blank" rel="noopener noreferrer">Learn how</a>
                                        }
                                        Essentials={
                                            <a href={MonthlyHelpLink.EA}
                                                target="_blank" rel="noopener noreferrer">Learn how</a>
                                        }
                                    />
                                } </p>
                        )}
                        cardText = {(
                            <div>
                                <p className={'no-bottom-margin'}>Emails with a list of outstanding invoices will be sent on the 3rd of each month.</p>
                            </div>
                        )}
                    />
                </FormTemplate>
            </div>
        )
    }
}
