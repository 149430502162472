import debounce from 'lodash/debounce'
import React from 'react'
import { FeedbackContext } from './FeedbackContext'

// Provided by `loadLeanEngage.js`
declare const leanengage: (
  type: 'triggerSurvey' | 'triggerFeedback',
  name: string
) => void

export type FeedbackContextProviderProps = {
    readonly surveyName: string
    readonly children?: React.ReactNode
}

export const FeedbackContextProvider: React.FunctionComponent<
  FeedbackContextProviderProps
> = props => {
  const onFeedback = debounce(
    () => {
      if (leanengage) {
        leanengage('triggerSurvey', props.surveyName)
      }
    },
    3000,
    { leading: true, trailing: false }
  )

  return (
    <FeedbackContext.Provider
      value={{
        state: {},
        actions: {
          onFeedback,
        },
      }}
    >
      {props.children}
    </FeedbackContext.Provider>
  )
}
