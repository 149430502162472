import { AddIcon, Button, DeleteIcon, EditIcon } from '@myob/myob-widgets'
import Table from '@myob/myob-widgets/lib/components/Table/Table'
import { Schedule } from '@myob/sme-cashin-reminders-domain'
import React from 'react'
import styled from 'styled-components'
import { AsyncScheduleDeleteModal } from './AsyncScheduleDeleteModal'
import { AsyncScheduleModal } from './AsyncScheduleModal'
import { Border } from './enums/Border'
import { ModalMode } from './enums/ModalMode'
import { Palette } from './enums/Palette'
import { Spacing } from './enums/Spacing'
import { TextBlock } from './TextBlock'

const StyledTextBlock = styled(TextBlock)`
  margin-bottom: 0;
`

const StyledContainer = styled.div`
  .table-data__cell {
    padding-top: ${Spacing.SMALL};
    padding-bottom: ${Spacing.SMALL};

    p {
      color: ${Palette.STANDARD};
      margin-bottom: 0px !important;
    }
  }

  .table-data__row {
    display: flex !important;
    &.text-gray {
      p {
        color: ${Palette.INACTIVE};
      }
    }

    &:last-child {
      border-bottom: ${Border.TABLE};
      border-top: ${Border.TABLE};

      .table-data__cell {
        .btn-link__content {
          display: flex;
          align-items: center;

          svg {
            margin-right: ${Spacing.SMALL};
          }
        }
      }
    }
  }
`

export type AsyncScheduleTableProps = {
  readonly data: ReadonlyArray<Schedule>
  readonly onUpdate: (schedule: Schedule, index?: number) => Promise<void>
  readonly onDelete: (schedule: Schedule) => Promise<void>
  readonly disabled?: boolean
}

export type AsyncScheduleTableState = {
  readonly deleteModalMode: ModalMode
  readonly updateModalMode: ModalMode
  readonly selectedSchedule?: Schedule
}

export class AsyncScheduleTable extends React.Component<
  AsyncScheduleTableProps,
  AsyncScheduleTableState
> {
  constructor(props: AsyncScheduleTableProps) {
    super(props)

    this.state = {
      deleteModalMode: ModalMode.HIDDEN,
      updateModalMode: ModalMode.HIDDEN,
      selectedSchedule: undefined,
    }
  }

  public render(): React.ReactNode {
    return (
      <StyledContainer>
        <Table>
          <Table.Body>
            {this.props.data
              .slice()
              .sort((a, b) => {
                return a.offset > b.offset ? -1 : 1
              })
              .map((schedule: Schedule, index: number) => {
                return (
                  <Table.Row key={index} isInactive={this.props.disabled}>
                    <Table.RowItem width="flex-1" valign="middle">
                      <StyledTextBlock>
                        {this.renderScheduleOffset(schedule.offset)}
                      </StyledTextBlock>
                    </Table.RowItem>
                    <Table.RowItem width="auto">
                      <Button
                        type="secondary"
                        className={`btn-edit-${index} schedule-button`}
                        size="xs"
                        onClick={() =>
                          this.onUpdateScheduleModal(ModalMode.EDIT, schedule)
                        }
                        disabled={this.props.disabled}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        type="secondary"
                        className={`btn-delete-${index} schedule-button`}
                        size="xs"
                        onClick={() =>
                          this.onDeleteScheduleModal(ModalMode.DELETE, schedule)
                        }
                        disabled={
                          this.props.data.length <= 1 || this.props.disabled
                        }
                      >
                        <DeleteIcon />
                      </Button>
                    </Table.RowItem>
                  </Table.Row>
                )
              })}
            <Table.Row isInactive={this.props.disabled}>
              <Table.RowItem>
                <Button
                  type="link"
                  className="btn-add"
                  onClick={() => this.onUpdateScheduleModal(ModalMode.ADD)}
                  disabled={this.props.data.length >= 5 || this.props.disabled}
                >
                  <AddIcon /> Add reminder
                </Button>
              </Table.RowItem>
            </Table.Row>
          </Table.Body>
        </Table>
        {this.state.updateModalMode !== ModalMode.HIDDEN
          ? this.renderScheduleModal()
          : null}
        {this.state.deleteModalMode === ModalMode.DELETE
          ? this.renderDeleteScheduleModal()
          : null}
      </StyledContainer>
    )
  }

  private readonly renderDeleteScheduleModal = () => {
    return (
      <AsyncScheduleDeleteModal
        onCancel={() => this.onDeleteScheduleModal(ModalMode.HIDDEN)}
        onDelete={this.onDelete}
      />
    )
  }

  private readonly renderScheduleOffset = (offset: number) => {
    if (offset === 0) {
      return 'On due date'
    }
    const noOfDays = Math.abs(offset)
    const strDay = noOfDays === 1 ? 'day' : 'days'
    if (offset > 0) {
      return `${noOfDays} ${strDay} before due date`
    }
    return `${noOfDays} ${strDay} after due date`
  }

  private readonly renderScheduleModal = () => {
    return (
      <AsyncScheduleModal
        mode={this.state.updateModalMode}
        data={this.props.data}
        id={
          this.state.selectedSchedule
            ? this.state.selectedSchedule.offset
            : undefined
        }
        onUpdate={this.onUpdate}
        onCancel={() => this.onUpdateScheduleModal(ModalMode.HIDDEN)}
      />
    )
  }

  private readonly onDelete = async (): Promise<void> => {
    this.onDeleteScheduleModal(ModalMode.HIDDEN)
    await this.props.onDelete(this.state.selectedSchedule!)
  }

  private readonly onDeleteScheduleModal = (
    mode: ModalMode,
    schedule?: Schedule
  ) => {
    this.setState({
      deleteModalMode: mode,
      selectedSchedule: schedule,
    })
  }

  private readonly onUpdateScheduleModal = (
    mode: ModalMode,
    schedule?: Schedule
  ) => {
    this.setState({
      updateModalMode: mode,
      selectedSchedule: schedule,
    })
  }

  private readonly onUpdate = async (
    schedule: Schedule,
    id?: number
  ): Promise<void> => {
    this.onUpdateScheduleModal(ModalMode.HIDDEN)
    await this.props.onUpdate(schedule, id)
  }
}
