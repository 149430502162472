import { IAuthTokenService } from './../services/interfaces/IAuthTokenService'
// @ts-ignore
import UrlHelper from './UrlHelper'

export type ArlWindowExternals = {
  readonly ArlBrowserFormGetAuthTokenCallback: () => string
}

export default class AuthenticationHelper {
  public static readonly obtainAuthToken = (
    authTokenService: IAuthTokenService,
    redirectURI: string,
    externals?: ArlWindowExternals,
    code?: string
  ): Promise<string | undefined> => {
    if (
      externals &&
      ((externals as unknown) as ArlWindowExternals)
        .ArlBrowserFormGetAuthTokenCallback
    ) {
      return Promise.resolve(
        ((externals as unknown) as ArlWindowExternals).ArlBrowserFormGetAuthTokenCallback()
      )
    }
    if (code) {
      return authTokenService.get(code, redirectURI)
    }
    return Promise.resolve(undefined)
  }

  public static readonly buildLoginURI = (
    idsLoginURI: string,
    idsClientId: string,
    redirectURI: string,
    resource: string
  ): string => {
    return (
      `${idsLoginURI}?` +
      `response_type=code&` +
      `client_id=${idsClientId}&` +
      `redirect_uri=${encodeURIComponent(redirectURI)}&` +
      `resource=${resource}`
    )
  }
}
