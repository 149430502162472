import MyobAlert from '@myob/myob-widgets/lib/components/Alert/Alert'
import React from 'react'
import styled from 'styled-components'
import { AlertType } from './enums/AlertType'
import { Spacing } from './enums/Spacing'

const StyledContainer = styled.div`
  margin-bottom: ${Spacing.MEDIUM};
`

export type AlertProps = {
    readonly className?: string
    readonly type: AlertType
    readonly onDismiss?: () => void
    readonly children?: React.ReactNode
}

export const Alert: React.FunctionComponent<AlertProps> = props => {
  return (
    <StyledContainer className={props.className}>
      <MyobAlert
        type={props.type}
        dismissAfter={5000}
        onDismiss={props.onDismiss}
      >
        {props.children}
      </MyobAlert>
    </StyledContainer>
  )
}
