import {Spinner} from '@myob/myob-widgets'
import {
  FilterAlgorithm,
  Schedule,
  ScheduleOffset,
} from '@myob/sme-cashin-reminders-domain'
import { TemplateUtils } from '@myob/sme-cashin-reminders-domain/dist'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { PreferencePage } from '../components/PreferencePage/GlobalPreference/GlobalPreferencePage'
import { AlertText } from '../components/widgets/enums/AlertText'
import { AlertType } from '../components/widgets/enums/AlertType'
import { AlertContextActions } from '../contexts/AlertContext'
import { FeedbackContextActions } from '../contexts/FeedbackContext'
import { RoutePath } from '../RoutePath'
import { AnalyticsEvent } from '../services/enums/AnalyticsEvent'
import { AnalyticsLabel } from '../services/enums/AnalyticsLabels'
import { AnalyticsType } from '../services/enums/AnalyticsType'
import { BusinessPreferencesNotFoundError } from '../services/errors/BusinessPreferencesNotFoundError'
import { IAnalyticsService } from '../services/interfaces/IAnalyticsService'
import { IBusinessPreferencesService } from '../services/interfaces/IBusinessPreferencesService'
import { PartialBusinessPreferences } from '../services/models/PartialBusinessPreferences'

export type GlobalProps = {
  readonly onGetBusinessPreferences: IBusinessPreferencesService['get']
  readonly onUpdateBusinessPreferences: IBusinessPreferencesService['update']
  readonly onTrack: IAnalyticsService['track']
  readonly onAlert: AlertContextActions['onShow']
  readonly onFeedback: FeedbackContextActions['onFeedback']
  readonly isSmep?: boolean
}

export type GlobalState = {
  readonly businessPreferences: PartialBusinessPreferences
  readonly loading: boolean
  readonly locked: boolean
  readonly error: boolean
  readonly redirect?: RoutePath
  readonly activeTab: string
  readonly showModal: boolean
}

export class Global extends React.Component<GlobalProps, GlobalState> {
  constructor(props: GlobalProps) {
    super(props)
    this.state = {
      businessPreferences: {
        dailyEnabled: false,
        dailyAlgorithm: FilterAlgorithm.BLACKLISTED,
        dailySchedule: [
          TemplateUtils.getDefaultSchedule(
            ScheduleOffset.THREE_DAYS_BEFORE_DUE
          ),
          TemplateUtils.getDefaultSchedule(ScheduleOffset.ONE_DAY_OVERDUE),
          TemplateUtils.getDefaultSchedule(ScheduleOffset.FORTNIGHT_OVERDUE),
        ],
        monthlyEnabled: false,
        monthlyAlgorithm: FilterAlgorithm.BLACKLISTED,
      },
      loading: true,
      locked: false,
      error: false,
      activeTab: 'daily',
      showModal: false,
    }
  }

  public async componentDidMount(): Promise<void> {
    this.props.onTrack(AnalyticsEvent.GLOBAL_VIEW, {}, AnalyticsType.ALL)
    this.props.onTrack(
      AnalyticsEvent.VIEW_TAB,
      {},
      AnalyticsType.GOOGLE_ANALYTICS_BY_TAB,
      AnalyticsLabel.DAILY
    )
    await this.onGetBusinessPreferences()
    this.setState({
      loading: false,
    })
  }

  public render(): React.ReactNode {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    if (this.state.loading) {
      return <Spinner />
    }
    return (
      <PreferencePage
        isSmep={this.props.isSmep}
        locked={this.state.locked}
        businessPreferences={this.state.businessPreferences}
        onUpdateDailyEnabled={this.onUpdateDailyEnabled}
        onUpdateDailyAlgorithm={this.onUpdateDailyAlgorithm}
        onUpdateDailySchedule={this.onUpdateDailySchedule}
        onDeleteDailySchedule={this.onDeleteDailySchedule}
        onUpdateMonthlyEnabled={this.onUpdateMonthlyEnabled}
        onUpdateMonthlyAlgorithm={this.onUpdateMonthlyAlgorithm}
        activeTab={this.state.activeTab}
        showModal={this.state.showModal}
        changeTab={this.changeTab}
        checkToShowModal={this.checkToShowModal}
        hideModal={this.hideModal}
        cancelModal={this.cancelModal}
        trackHelpClick={this.trackHelpClick}
      />
    )
  }

  private readonly onGetBusinessPreferences = async () => {
    try {
      const businessPreferences = await this.props.onGetBusinessPreferences()
      this.setState({
        businessPreferences,
      })
    } catch (e) {
      if (e instanceof BusinessPreferencesNotFoundError) {
        // Use default state
        return
      }

      this.setState({
        redirect: RoutePath.ERROR,
      })
    }
  }

  private readonly onUpdateBusinessPreferences = async (
    businessPreferences: PartialBusinessPreferences,
    successMessage: AlertText,
    errorMessage: AlertText,
    analyticsEvent: AnalyticsEvent
  ) => {
    try {
      this.setState({ locked: true })

      await this.props.onUpdateBusinessPreferences(businessPreferences)

      this.setState({ businessPreferences })

      this.onSuccess(successMessage)
      this.props.onTrack(
        analyticsEvent,
        businessPreferences,
        AnalyticsType.SEGMENT
      )
    } catch (e) {
      this.onError(errorMessage)
    } finally {
      this.setState({ locked: false })
    }
  }

  private readonly onUpdateDailyEnabled = async (): Promise<void> => {
    const updatedPreferences = {
      ...this.state.businessPreferences,
      dailyEnabled: !this.state.businessPreferences.dailyEnabled,
    }
    await this.onUpdateBusinessPreferences(
      updatedPreferences,
      updatedPreferences.dailyEnabled
        ? AlertText.BUSINESS_PREFERENCES_DAILY_ENABLED_UPDATE_TRUE
        : AlertText.BUSINESS_PREFERENCES_DAILY_ENABLED_UPDATE_FALSE,
      AlertText.BUSINESS_PREFERENCES_DAILY_ENABLED_UPDATE_ERROR,
      AnalyticsEvent.GLOBAL_DAILY_ENABLED_CHANGE
    )
    const analyticsEvent = updatedPreferences.dailyEnabled
      ? AnalyticsEvent.ENABLE
      : AnalyticsEvent.DISABLE
    this.props.onTrack(
      analyticsEvent,
      {},
      AnalyticsType.GOOGLE_ANALYTICS_ONLY,
      AnalyticsLabel.DAILY
    )
  }

  private readonly onUpdateDailyAlgorithm = async (): Promise<void> => {
    const updatedPreferences = {
      ...this.state.businessPreferences,
      dailyAlgorithm:
        this.state.businessPreferences.dailyAlgorithm ===
        FilterAlgorithm.BLACKLISTED
          ? FilterAlgorithm.WHITELISTED
          : FilterAlgorithm.BLACKLISTED,
    }

    await this.onUpdateBusinessPreferences(
      updatedPreferences,
      updatedPreferences.dailyAlgorithm === FilterAlgorithm.BLACKLISTED
        ? AlertText.BUSINESS_PREFERENCES_DAILY_ALGORITHM_UPDATE_BLACKLISTED
        : AlertText.BUSINESS_PREFERENCES_DAILY_ALGORITHM_UPDATE_WHITELISTED,
      AlertText.BUSINESS_PREFERENCES_DAILY_ALGORITHM_UPDATE_ERROR,
      AnalyticsEvent.GLOBAL_DAILY_ALGORITHM_CHANGE
    )
  }

  private readonly onUpdateDailySchedule = async (
    schedule: Schedule,
    id?: number
  ): Promise<void> => {
    const updatedDailySchedule =
      id !== undefined && id !== null
        ? this.state.businessPreferences.dailySchedule
            .filter((s: Schedule) => s.offset !== id)
            .concat([schedule])
        : this.state.businessPreferences.dailySchedule.concat([schedule])

    if (updatedDailySchedule.length > 5) {
      // DailySchedule cannot have more than 5 items.
      // This should never happened as the button should be disabled.
      return
    }

    const updatedPreferences = {
      ...this.state.businessPreferences,
      dailySchedule: updatedDailySchedule,
    }

    await this.onUpdateBusinessPreferences(
      updatedPreferences,
      AlertText.BUSINESS_PREFERENCES_DAILY_SCHEDULE_UPDATE,
      AlertText.BUSINESS_PREFERENCES_DAILY_SCHEDULE_UPDATE_ERROR,
      AnalyticsEvent.GLOBAL_DAILY_SCHEDULE_CHANGE
    )
  }

  private readonly onDeleteDailySchedule = async (
    schedule: Schedule
  ): Promise<void> => {
    if (!schedule || this.state.businessPreferences.dailySchedule.length <= 1) {
      // DailySchedule must have at least one item.
      // This should never happened as the button should be disabled.
      return
    }

    const updatedDailySchedule = this.state.businessPreferences.dailySchedule.filter(
      s => s.offset !== schedule.offset
    )

    const updatedPreferences = {
      ...this.state.businessPreferences,
      dailySchedule: updatedDailySchedule,
    }

    await this.onUpdateBusinessPreferences(
      updatedPreferences,
      AlertText.BUSINESS_PREFERENCES_DAILY_SCHEDULE_DELETE,
      AlertText.BUSINESS_PREFERENCES_DAILY_SCHEDULE_DELETE_ERROR,
      AnalyticsEvent.GLOBAL_DAILY_SCHEDULE_CHANGE
    )
  }

  private readonly onUpdateMonthlyEnabled = async (): Promise<void> => {
    const updatedPreferences = {
      ...this.state.businessPreferences,
      monthlyEnabled: !this.state.businessPreferences.monthlyEnabled,
    }

    await this.onUpdateBusinessPreferences(
      updatedPreferences,
      updatedPreferences.monthlyEnabled
        ? AlertText.BUSINESS_PREFERENCES_MONTHLY_ENABLED_UPDATE_TRUE
        : AlertText.BUSINESS_PREFERENCES_MONTHLY_ENABLED_UPDATE_FALSE,
      AlertText.BUSINESS_PREFERENCES_MONTHLY_ENABLED_UPDATE_ERROR,
      AnalyticsEvent.GLOBAL_MONTHLY_ENABLED_CHANGE
    )
    const analyticsEvent = updatedPreferences.monthlyEnabled
      ? AnalyticsEvent.ENABLE
      : AnalyticsEvent.DISABLE
    this.props.onTrack(
      analyticsEvent,
      {},
      AnalyticsType.GOOGLE_ANALYTICS_ONLY,
      AnalyticsLabel.MONTHLY
    )
  }

  private readonly onUpdateMonthlyAlgorithm = async (): Promise<void> => {
    const updatedPreferences = {
      ...this.state.businessPreferences,
      monthlyAlgorithm:
        this.state.businessPreferences.monthlyAlgorithm ===
        FilterAlgorithm.BLACKLISTED
          ? FilterAlgorithm.WHITELISTED
          : FilterAlgorithm.BLACKLISTED,
    }

    await this.onUpdateBusinessPreferences(
      updatedPreferences,
      updatedPreferences.monthlyAlgorithm === FilterAlgorithm.BLACKLISTED
        ? AlertText.BUSINESS_PREFERENCES_MONTHLY_ALGORITHM_UPDATE_BLACKLISTED
        : AlertText.BUSINESS_PREFERENCES_MONTHLY_ALGORITHM_UPDATE_WHITELISTED,
      AlertText.BUSINESS_PREFERENCES_MONTHLY_ALGORITHM_UPDATE_ERROR,
      AnalyticsEvent.GLOBAL_MONTHLY_ALGORITHM_CHANGE
    )
  }

  private readonly changeTab = ({
    selected,
  }: {
    readonly selected: string
  }) => {
    this.setState({
      ...this.state,
      activeTab: selected,
    })
    const analyticsLabel =
      selected === 'daily' ? AnalyticsLabel.DAILY : AnalyticsLabel.MONTHLY
    this.props.onTrack(
      AnalyticsEvent.VIEW_TAB,
      {},
      AnalyticsType.GOOGLE_ANALYTICS_BY_TAB,
      analyticsLabel
    )
  }

  private readonly checkToShowModal = (
    isEnabled: boolean,
    onUpdate: () => Promise<void>
  ) => {
    if (isEnabled === false) {
      this.setState({
        ...this.state,
        showModal: true,
      })
      const analyticsLabel =
        this.state.activeTab === 'daily'
          ? AnalyticsLabel.DAILY
          : AnalyticsLabel.MONTHLY
      this.props.onTrack(
        AnalyticsEvent.OPEN_MODAL,
        {},
        AnalyticsType.GOOGLE_ANALYTICS_BY_TAB,
        analyticsLabel
      )
    } else {
      onUpdate()
    }
  }

  private readonly trackHelpClick = (analyticsLabel: string) => {
    this.props.onTrack(
      AnalyticsEvent.MODAL_HELP,
      {},
      AnalyticsType.GOOGLE_ANALYTICS_BY_TAB,
      analyticsLabel
    )
  }

  private readonly hideModal = () => {
    this.setState({
      ...this.state,
      showModal: false,
    })
  }

  private readonly cancelModal = () => {
    const analyticsLabel =
      this.state.activeTab === 'daily'
        ? AnalyticsLabel.DAILY
        : AnalyticsLabel.MONTHLY
    this.props.onTrack(
      AnalyticsEvent.CANCEL_MODAL,
      {},
      AnalyticsType.GOOGLE_ANALYTICS_BY_TAB,
      analyticsLabel
    )
    this.hideModal()
  }

  private readonly onError = (text: AlertText) => {
    this.props.onAlert(AlertType.ERROR, text)
    this.setState({
      error: true,
    })
  }

  private readonly onSuccess = (text: AlertText) => {
    this.props.onAlert(AlertType.SUCCESS, text)
  }
}
