import React from 'react';
import { Card, PageHead, Checkbox, RadioButton } from '@myob/myob-widgets'
import styles from './TabbedPreferences.css';
import { ConsumerRenderer } from '../../widgets/ConsumerRenderer'
import { MonthlyHelpLink } from '../../widgets/enums/HelpLink'

export class MonthlyPreferences extends React.Component {
    checkToShowModal = () => {
        this.props.checkToShowModal(this.props.businessPreferences.monthlyEnabled, this.props.onUpdateMonthlyEnabled)
    }
    render = () => {
        return (
            <div>
                <Card header={<Card.Header child={<PageHead title="Enable unpaid invoice summaries"></PageHead>} />}>
                    <p>Send your customers an email that lets them view all their unpaid online invoices. 
                        Emails will be sent on the 3rd of each month - you’ll receive an email reminder a few days before.</p>
                    <div className={'reminders-checkbox'}>
                        <Checkbox
                            name={'monthlyEnabledCheckbox'}
                            label={'Send unpaid invoice summaries'}
                            checked={this.props.businessPreferences.monthlyEnabled}
                            onChange={this.checkToShowModal}
                            disabled={this.props.locked}
                        />
                    </div>
                </Card>
                <Card header={<Card.Header child={<PageHead title="Select your customers"></PageHead>} />}>
                    <p>Choose which customers to send unpaid invoice summaries to.</p>
                    <div className={'reminders-radio'}>
                        <RadioButton
                            checked={this.props.businessPreferences.monthlyAlgorithm === 'blacklisted'}
                            name={'blacklisted'}
                            key={'blacklisted'}
                            value={'blacklisted'}
                            label={
                                <div>
                                    <p className='algorithm-label'>Send to all customers</p>
                                    <p className='algorithm-description'>Summaries will be sent to all customers.</p>
                                    <br/>
                                </div>
                            }
                            onChange={this.props.onUpdateMonthlyAlgorithm}
                        />
                        <RadioButton
                            checked={this.props.businessPreferences.monthlyAlgorithm === 'whitelisted'}
                            name={'whitelisted'}
                            key={'whitelisted'}
                            value={'whitelisted'}
                            label={
                                <div>
                                    <p className='algorithm-label'>Send to specific customers</p>
                                    <p className='algorithm-description'>Summaries will be sent to customers that you choose. Open each customer’s details and go to their invoice reminder settings.</p>
                                    <p className='algorithm-description'>
                                        <ConsumerRenderer
                                            ARL ={
                                                <a
                                                    href={this.props.isSmep ? MonthlyHelpLink.SMEP : MonthlyHelpLink.ARL}
                                                    target="_blank" rel="noopener noreferrer"
                                                >
                                                    Learn how
                                                </a>
                                            }
                                            Essentials={
                                                <a
                                                    href={MonthlyHelpLink.EA}
                                                    target="_blank" rel="noopener noreferrer"
                                                >
                                                    Learn how
                                                </a>
                                            }
                                        />
                                    </p>
                                </div>
                            }
                            onChange={this.props.onUpdateMonthlyAlgorithm}
                        />
                    </div>
                </Card>
            </div>
        )
    }
}
