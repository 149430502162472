import { AxiosError } from 'axios'
import has from 'lodash/has'

const isAxiosErrorWithStatus = (e: Error | AxiosError): e is AxiosError => {
  return has(e, 'response.status')
}

export const is404 = (e: Error | AxiosError): e is AxiosError => {
  if (isAxiosErrorWithStatus(e)) {
    return e.response!.status === 404
  }

  return false
}
