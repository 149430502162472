import {HelpIcon} from '@myob/myob-widgets'
import React from 'react'
import { IconLink } from '../widgets/IconLink'

export type HelpLinkProps = {
  readonly className?: string
  readonly url: string
}

export const HelpLink: React.FunctionComponent<HelpLinkProps> = props => {
  return (
    <IconLink
      className={props.className}
      href={props.url}
      onClick={undefined}
      icon={<HelpIcon title="help" />}
    >
      Help
    </IconLink>
  )
}
