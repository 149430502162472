import { BusinessPreferences, Region } from '@myob/sme-cashin-reminders-domain'
import axios from 'axios'
import { BusinessPreferencesService } from '../BusinessPreferencesService'
import { IBusinessPreferencesService } from './../interfaces/IBusinessPreferencesService'

export const createBusinessPreferencesService = (
  url: string,
  authToken: string,
  businessId: BusinessPreferences['businessId'],
  consumer: BusinessPreferences['consumer'],
  region: Region
): IBusinessPreferencesService => {
  return new BusinessPreferencesService(
    axios.create({
      baseURL: url,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    }),
    businessId,
    consumer,
    region
  )
}
