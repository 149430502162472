import { flxPaletteWhite } from '@myob/myob-styles/dist/design-tokens/js/design-tokens'
import React from 'react'
import styled from 'styled-components'

export type ViewportContainerProps = {
  readonly className?: string
  readonly children?: React.ReactNode
}

const StyledContainer = styled.div`
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  background: ${flxPaletteWhite};
  height: 100%;
  & > * {
    flex: 1 1 auto;
  }
`

export const ViewportContainer: React.FunctionComponent<
  ViewportContainerProps
> = props => {
  return (
    <StyledContainer className={props.className}>
      {props.children}
    </StyledContainer>
  )
}
