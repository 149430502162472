import {
  flxBorderTableRowOutline,
  flxBorderThick,
  flxBorderThin,
} from '@myob/myob-styles/dist/design-tokens/js/design-tokens'

export const Border = {
  THIN: flxBorderThin,
  THICK: flxBorderThick,
  TABLE: flxBorderTableRowOutline,
}
