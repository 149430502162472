import React from 'react'
import { AlertText } from '../components/widgets/enums/AlertText'
import { AlertType } from '../components/widgets/enums/AlertType'

export type AlertContextState =
  | {
      readonly type: null
      readonly message: null
      readonly show: false
    }
  | {
      readonly type: AlertType
      readonly message: AlertText
      readonly show: true
    }

export type AlertContextActions = {
  readonly onShow: (type: AlertType, message: AlertText) => void
  readonly onDismiss: () => void
}

export const AlertContext = React.createContext<{
  readonly state: AlertContextState
  readonly actions: AlertContextActions
}>({
  state: {
    type: null,
    message: null,
    show: false,
  },
  actions: {
    onShow: (type, text) => undefined,
    onDismiss: () => undefined,
  },
})
