import React from 'react'
import image from './ErrorSplashImage.png'

export type ErrorSplashImageProps = {
  readonly className?: string
}

export const ErrorSplashImage: React.FunctionComponent<
  ErrorSplashImageProps
> = props => {
  return <img className={props.className} src={image} alt="customer error" />
}
