import React from 'react'
import { ConsumerContext, ConsumerContextState } from './ConsumerContext'

export const ConsumerContextProvider: React.FunctionComponent<
  ConsumerContextState
> = props => {
  return (
    <ConsumerContext.Provider
      value={{
        state: {
          consumer: props.consumer,
        },
      }}
    >
      {props.children}
    </ConsumerContext.Provider>
  )
}
