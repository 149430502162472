import React from 'react'
import styled from 'styled-components'
import { TextBlock } from '../widgets/TextBlock'
import { Spacing } from './enums/Spacing'

export type IconLinkProps =
  | {
     readonly children?: React.ReactNode
      readonly className?: string
      readonly href: undefined
      readonly onClick: () => void
      readonly icon: React.ReactNode
    }
  | {
      readonly children?: React.ReactNode
      readonly className?: string
      readonly href: string
      readonly onClick: undefined
      readonly icon: React.ReactNode
    }

const StyledButtonText = styled(TextBlock)`
  margin: 0 !important;
`
const StyledLink = styled.a`
  display: flex;
  flex-flow: row;
  align-items: center;

  ${StyledButtonText} {
    display: block;
    margin-left: ${Spacing.SMALL};
  }
`

export const IconLink: React.FunctionComponent<IconLinkProps> = props => {
  return (
    <div className={props.className} onClick={props.onClick}>
      <StyledLink href={props.href} target={'_blank'}>
        {props.icon}
        <StyledButtonText>{props.children}</StyledButtonText>
      </StyledLink>
    </div>
  )
}
