export enum DailyHelpLink {
  ARL = 'https://help.myob.com/wiki/x/WyLnAQ',
  SMEP = 'https://help.myob.com/wiki/x/va9qAg',
  EA = 'https://help.myob.com/wiki/x/Rl3nAQ',
}

export enum MonthlyHelpLink {
  ARL = 'https://help.myob.com/wiki/x/ooVqAg',
  SMEP = 'https://help.myob.com/wiki/x/GLBqAg',
  EA = 'https://help.myob.com/wiki/x/G4VqAg',
}
