import { AccessToken } from '@myob/sme-cashin-reminders-domain'
import { AxiosInstance } from 'axios'
import uuid from 'uuid'
import { IAuthTokenService } from './interfaces/IAuthTokenService'

export class AuthTokenService implements IAuthTokenService {
  constructor(private readonly axios: AxiosInstance) {}

  public readonly get = async (
    code: string,
    redirectUri: string
  ): Promise<string> => {
    // tslint:disable-next-line:no-console
    console.log('AuthTokenService code: ' + code)
    // tslint:disable-next-line:no-console
    console.log('AuthTokenService redirectUri: ' + redirectUri)
    const result = await this.axios.request({
      method: 'POST',
      url: '/v1/token',
      headers: {
        'x-myobapi-requestid': uuid.v4(),
      },
      data: {
        code,
        redirectUri,
      },
    })
    const accessToken: AccessToken = result.data
    // tslint:disable-next-line:no-console
    console.log('accessToken: ' + accessToken)
    return accessToken.token
  }
}
