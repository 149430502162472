import {Button, HelpIcon} from '@myob/myob-widgets'
import Label from '@myob/myob-widgets/lib/components/Label/Label'
import Popover from '@myob/myob-widgets/lib/components/Popover/Popover'
import PopoverBody from '@myob/myob-widgets/lib/components/Popover/PopoverBody'
import PopoverHeader from '@myob/myob-widgets/lib/components/Popover/PopoverHeader'
import React from 'react'
import styled from 'styled-components'
import { Heading } from './Heading'
import {
  TemplateEditorInput,
  TemplateEditorInputData,
} from './TemplateEditorInput'
import { TextBlock } from './TextBlock'

const StyledContainer = styled.div`
  display: flex;
  flex-flow: column;

  @media (min-width: 700px) {
    flex-flow: row;
  }

  .label {
    font-weight: 400;
    margin-bottom: 2px;
  }

  > * {
    margin-right: 5px;

    &:last-child {
      margin-right: 0px;
    }
  }
`

const StyledInputContainer = styled.div`
  flex-grow: 1;
  width: 100%;

  @media (min-width: 700px) {
    width: 70%;
  }
`

const StyledPlaceholderContainer = styled.div`
  display: flex;
  flex-flow: column wrap;

  > button {
    margin-bottom: 5px;
  }
`

const StyledPlaceHolderLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2px;

  > * {
    margin-right: 2px;
  }

  .flx-popover {
    line-height: 1rem;
  }
`

export type TemplatePlaceholderType =
  | 'square-brackets'
  | 'double-curly-brackets'

export type TemplateItem = {
  readonly value: string
  readonly displayName?: string
}

export type TemplateEditorProps = {
  readonly placeholders: ReadonlyArray<TemplateItem>
  readonly placeholderStyle: TemplatePlaceholderType
  readonly inputs: ReadonlyArray<TemplateEditorInputData>
  readonly container?: string
}

export type TemplateEditorState = {
  readonly selectedInput?: TemplateEditorInput
}

export class TemplateEditor extends React.Component<
  TemplateEditorProps,
  TemplateEditorState
> {
  public readonly placeholderPattern?: string
  public readonly placeholderPrefix?: string
  public readonly placeholderSuffix?: string

  constructor(props: TemplateEditorProps) {
    super(props)

    this.placeholderPrefix = this.getPlaceHolderPrefix(props.placeholderStyle)
    this.placeholderSuffix = this.getPlaceHolderSuffix(props.placeholderStyle)
    this.placeholderPattern = this.getPlaceholderPatten(
      props.placeholders,
      this.placeholderPrefix,
      this.placeholderSuffix
    )

    this.state = {}
  }

  public render(): React.ReactNode {
    return (
      <StyledContainer>
        {this.renderInputs()}
        {this.props.placeholders.length > 0
          ? this.renderPlaceholder()
          : undefined}
      </StyledContainer>
    )
  }

  private readonly getPlaceholderPatten = (
    placeholders: ReadonlyArray<TemplateItem>,
    prefix?: string,
    suffix?: string
  ): string | undefined => {
    const replaceRegex = /[-[\]{}()*+?.,\\^$|]/g
    const replaceWith = '\\$&'
    if (placeholders.length > 0) {
      return placeholders
        .map((item: TemplateItem) =>
          `${prefix}${item.value}${suffix}`.replace(replaceRegex, replaceWith)
        )
        .join('|')
    }

    if (prefix || suffix) {
      const escapedPrefix = prefix
        ? prefix.replace(replaceRegex, replaceWith)
        : ''
      const escapedSuffix = suffix
        ? suffix.replace(replaceRegex, replaceWith)
        : ''
      return `${escapedPrefix}.*?${escapedSuffix}`
    }

    return undefined
  }

  private readonly getPlaceHolderPrefix = (
    placeholder?: TemplatePlaceholderType
  ): string => {
    switch (placeholder) {
      case 'double-curly-brackets':
        return '{{'
      case 'square-brackets':
        return '['
      default:
        return ''
    }
  }

  private readonly getPlaceHolderSuffix = (
    placeholder?: TemplatePlaceholderType
  ): string => {
    switch (placeholder) {
      case 'double-curly-brackets':
        return '}}'
      case 'square-brackets':
        return ']'
      default:
        return ''
    }
  }

  private readonly onInputFocus = (input: TemplateEditorInput) => {
    this.setState({
      selectedInput: input,
    })
  }

  private readonly onTemplateItemClick = (value: string) => {
    if (this.state.selectedInput) {
      const placeholder = `${this.placeholderPrefix}${value}${
        this.placeholderSuffix
      }`
      this.state.selectedInput.insertPlaceholder(placeholder)
    }
  }

  private readonly renderInputs = (): React.ReactNode => {
    return (
      <StyledInputContainer>
        {this.props.inputs.map(
          (input: TemplateEditorInputData, index: number) => {
            return (
              <TemplateEditorInput
                data={input}
                onFocus={this.onInputFocus}
                key={index}
                pattern={this.placeholderPattern}
              />
            )
          }
        )}
      </StyledInputContainer>
    )
  }

  private readonly renderPlaceholder = (): React.ReactNode => {
    return (
      <StyledPlaceholderContainer className={'placeholders-group'}>
        {this.renderPlaceholderInfo()}
        {this.props.placeholders.map((item: TemplateItem, index: number) => {
          return (
            <Button
              type={'secondary'}
              key={index}
              onClick={this.onTemplateItemClick.bind(null, item.value)}
            >
              {item.displayName || item.value}
            </Button>
          )
        })}
      </StyledPlaceholderContainer>
    )
  }

  private readonly renderPlaceholderInfo = (): React.ReactNode => {
    const PlaceHolderInfo = () => (
      <div>
        <TextBlock>
          Whenever you send a reminder, the tags will be replaced with
          information found in your customer cards. Here’s how to use them:
        </TextBlock>
        <TextBlock>
          Insert tags anywhere in the subject or message. The tag will be
          replaced with the correct details when the email is sent.
        </TextBlock>
      </div>
    )
    return (
      <StyledPlaceHolderLabel>
        <Label>Insert tags</Label>
        <Popover
          children={<HelpIcon />}
          preferPlace="below"
          header={
            <PopoverHeader child={<Heading level={3}>Using tags</Heading>} />
          }
          body={<PopoverBody child={<PlaceHolderInfo />} />}
          appendTarget={this.props.container}
          closeOnOuterAction={true}
        />
      </StyledPlaceHolderLabel>
    )
  }
}
