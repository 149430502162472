import React from 'react'
import { CustomerErrorPage } from '../components/CustomerErrorPage/CustomerErrorPage'
import { AnalyticsEvent } from '../services/enums/AnalyticsEvent'
import { IAnalyticsService } from '../services/interfaces/IAnalyticsService'

export type CustomerErrorProps = {
  readonly onTrack: IAnalyticsService['track']
  readonly isSmep?: boolean
}

export class CustomerError extends React.Component<CustomerErrorProps, {}> {
  public readonly componentDidMount = () => {
    this.props.onTrack(AnalyticsEvent.CUSTOMER_ERROR_VIEW)
  }

  public readonly render = () => {
    return <CustomerErrorPage isSmep={this.props.isSmep} />
  }
}
