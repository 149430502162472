import * as url from 'url'
import get from 'lodash/get'
import React from 'react'
import { DebugInformation } from './components/DebugInformation'
import { Route, Switch, withRouter } from 'react-router-dom'
import { GenericError } from './containers/GenericError'
import { Customer } from './containers/Customer'
import { CustomerError } from './containers/CustomerError'
import { RoutePath } from './RoutePath'
import { ViewportContainer } from './components/widgets/ViewportContainer'
import { Global } from './containers/Global'
import { createBusinessPreferencesService } from './services/factories/createBusinessPreferencesService'
import { createAnalyticsService } from './services/factories/createAnalyticsService'
import { createBusinessCustomerPreferencesService } from './services/factories/createBusinessCustomerPreferencesService'
import { ConsumerContextProvider } from './contexts/ConsumerContextProvider'
import { AlertContextProvider } from './contexts/AlertContextProvider'
import { AlertContext } from './contexts/AlertContext'
import { AnalyticsSource } from './services/enums/AnalyticsSource'
import { FeedbackContextProvider } from './contexts/FeedbackContextProvider'
import { FeedbackContext } from './contexts/FeedbackContext'
import ReactGA from 'react-ga';	
import styles from './App.css'

class App extends React.Component {
  constructor(props) {
    super(props)
    ReactGA.initialize('UA-62960155-1');
    this.authToken = props.authToken
    this.query = props.query

    this.businessPreferencesService = createBusinessPreferencesService(
      process.env.REACT_APP_API_ENDPOINT,
      this.authToken,
      get(this.query, 'businessId'),
      get(this.query, 'consumer'),
      get(this.query, 'region')
    )

    this.businessCustomerPreferenceService = createBusinessCustomerPreferencesService(
      process.env.REACT_APP_API_ENDPOINT,
      this.authToken,
      get(this.query, 'businessId'),
      get(this.query, 'id')
    )
    
    this.analyticsService = createAnalyticsService(
      process.env.REACT_APP_ANALYTICS_API_ENDPOINT,
      get(this.query, 'businessId'),
      get(this.query, 'consumer'),
      AnalyticsSource[get(this.query, 'origin')] || 'invoice-reminders',
      ReactGA
    )

    this.surveyName = process.env.REACT_APP_LEAN_ENGAGE_SURVEY
    this.isSmep = get(this.query, 'source','').toUpperCase() === 'SMEP';
    this.state = {}
  }

  componentDidMount = () => {
    if (!RoutePath[get(this.query, 'origin')]) {
      this.onRoute(RoutePath.ERROR)
    }

    this.onRoute(RoutePath[get(this.query, 'origin')])
  }

  render() {
    return (
        <ConsumerContextProvider consumer={get(this.query, 'consumer')}>
        <AlertContextProvider>
          <FeedbackContextProvider surveyName={this.surveyName}>
            <ViewportContainer className={get(this.props, 'className')}>
              <Switch>
                <Route
                  path={RoutePath.CUSTOMER_ERROR}
                  render={this.renderCustomerErrorRoute}
                />
                <Route
                  path={RoutePath.CUSTOMER}
                  render={this.renderCustomerRoute}
                />
                <Route
                  path={RoutePath.GLOBAL}
                  render={this.renderGlobalRoute}
                />
                <Route
                  path={RoutePath.ERROR}
                  render={this.renderGenericErrorRoute}
                />
              </Switch>

              {get(this.query, 'debug') ? <DebugInformation /> : null}
            </ViewportContainer>
          </FeedbackContextProvider>
        </AlertContextProvider>
      </ConsumerContextProvider>
    )
  }

  onRoute = pathname => {
    this.props.history.push(
      url.format({
        pathname,
        search: this.props.history.location.search,
      })
    )
  }

  renderGlobalRoute = () => {
    return (
      <AlertContext.Consumer>
        {alertContext => (
          <FeedbackContext.Consumer>
            {feedbackContext => (
              <Global
                isSmep={this.isSmep}
                onGetBusinessPreferences={this.businessPreferencesService.get}
                onUpdateBusinessPreferences={
                  this.businessPreferencesService.update
                }
                onTrack={this.analyticsService.track}
                onAlert={alertContext.actions.onShow}
                onFeedback={feedbackContext.actions.onFeedback}
              />
            )}
          </FeedbackContext.Consumer>
        )}
      </AlertContext.Consumer>
    )
  }

  renderCustomerRoute = () => {
    return (
      <AlertContext.Consumer>
        {context => (
          <Customer
            isSmep={this.isSmep}
            onGetBusinessPreferences={this.businessPreferencesService.get}
            onGetBusinessCustomerPreferences={
              this.businessCustomerPreferenceService.get
            }
            onUpdateBusinessCustomerPreferences={
              this.businessCustomerPreferenceService.update
            }
            onTrack={this.analyticsService.track}
            onAlert={context.actions.onShow}
          />
        )}
      </AlertContext.Consumer>
    )
  }

  renderCustomerErrorRoute = () => {
    return <CustomerError isSmep={this.isSmep} onTrack={this.analyticsService.track} />
  }

  renderGenericErrorRoute = () => {
    return <GenericError />
  }
}

export default withRouter(App)
