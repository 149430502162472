import React from 'react';
import { Modal, Button } from '@myob/myob-widgets'
import { ConsumerRenderer } from '../../widgets/ConsumerRenderer'
import { AnalyticsLabel } from '../../../services/enums/AnalyticsLabels'
import { DailyHelpLink } from '../../widgets/enums/HelpLink'

export class UpdatePreferencesModal extends React.Component {

    render = () => {
        return (
            <Modal
                title={this.props.modalHeading}
                show={this.props.showModal}
                onCancel={() => {this.props.hideModal()}}
            >
                <Modal.Body>
                    {this.props.modalText}
                    <ConsumerRenderer
                        ARL={<a href={this.props.isSmep ? DailyHelpLink.SMEP : DailyHelpLink.ARL} target="_blank" rel="noopener noreferrer"
                                onClick={()=>{
                                    const analyticsLabel = this.props.modalType==='daily' ? AnalyticsLabel.DAILY : AnalyticsLabel.MONTHLY;
                                    this.props.trackHelpClick(analyticsLabel);
                                }}
                            >Learn more</a>}
                        Essentials={<a href={DailyHelpLink.EA} target="_blank" rel="noopener noreferrer"
                            onClick={()=>{
                                const analyticsLabel = this.props.modalType==='daily' ? AnalyticsLabel.DAILY : AnalyticsLabel.MONTHLY;
                                this.props.trackHelpClick(analyticsLabel);
                            }}
                        >Learn more</a>}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="secondary"
                        onClick={() => {this.props.cancelModal()}}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            this.props.onAcceptModal();
                            this.props.hideModal();
                        }}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
