import { BusinessPreferences } from '@myob/sme-cashin-reminders-domain'
import { AxiosInstance } from 'axios'
import { USER_ANALYTICS_CATEGORY } from '../services/AnalyticsConstants'
import { AnalyticsEvent } from './enums/AnalyticsEvent'
import { AnalyticsType } from './enums/AnalyticsType'
import { IAnalyticsService } from './interfaces/IAnalyticsService'

export class AnalyticsService implements IAnalyticsService {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly businessId: BusinessPreferences['businessId'],
    private readonly consumer: BusinessPreferences['consumer'],
    private readonly source: string,
    private readonly ReactGA: any
  ) {}

  public readonly track = async (
    event: AnalyticsEvent,
    props?: object,
    analyticsType?: AnalyticsType,
    label?: string
  ) => {
    if (
      analyticsType === AnalyticsType.GOOGLE_ANALYTICS_BY_TAB ||
      analyticsType === AnalyticsType.GOOGLE_ANALYTICS_ONLY ||
      analyticsType === AnalyticsType.ALL
    ) {
      await this.trackGoogleAnalytics(event, label)
    }
    if (
      !analyticsType ||
      analyticsType === AnalyticsType.SEGMENT ||
      analyticsType === AnalyticsType.ALL
    ) {
      await this.trackSegment(event, props)
    } else if (analyticsType === AnalyticsType.GOOGLE_ANALYTICS_BY_TAB) {
      await this.trackSegment(`invoice-reminders-${event}`, props, label)
    }
  }

  private readonly trackGoogleAnalytics = async (
    event: AnalyticsEvent,
    label: string | undefined
  ) => {
    this.ReactGA.event({
      category: USER_ANALYTICS_CATEGORY,
      action: event,
      label,
    })
  }

  private readonly trackSegment = async (
    event: string,
    props?: object,
    type?: string
  ) => {
    this.axios.request({
      method: 'POST',
      url: '/segment',
      data: {
        userId: `${this.consumer}/${this.businessId}`,
        eventName: event,
        properties: {
          reminderType: type,
          company: {
            id: this.businessId,
          },
          consumer: this.consumer,
          source: this.source,
          ...props,
        },
      },
    })
  }
}
