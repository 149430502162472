import React from 'react'
import ReactDOM from 'react-dom'
import Auth from './Auth'
import { BrowserRouter } from 'react-router-dom'
import '@myob/myob-styles/dist/styles/myob-clean.css'
import '@myob/myob-styles'

ReactDOM.render(
  <BrowserRouter>
    <Auth />
  </BrowserRouter>,
  document.getElementById('root')
)
