import { Consumer, Region } from '@myob/sme-cashin-reminders-domain'
import * as queryStringLibrary from 'querystring'

export type QueryParameters =
  | {
      readonly businessId: string
      readonly consumer: Consumer.ESSENTIALS
      readonly id?: string
      readonly origin: string // @TODO this should be an enum (expand on RoutePaths)
      readonly region: Region
    }
  | {
      readonly businessId: string
      readonly consumer: Consumer.ACCOUNT_RIGHT_LIVE
      readonly id?: string
      readonly origin: string
      readonly source?: string
    }
  | {
      readonly code: string
    }

export default class UrlHelper {
  public static readonly extractQueryParameters = (
    queryString: string
  ): QueryParameters => {
    const queryParameters = queryStringLibrary.parse(queryString)

    const consumer = queryParameters.consumer as Consumer
    const origin = queryParameters.origin
      ? (queryParameters.origin as string).toUpperCase()
      : 'ERROR'

    // @TODO we should do some validation on this incoming query params object

    switch (consumer) {
      case Consumer.ACCOUNT_RIGHT_LIVE:
        return {
          businessId: queryParameters.cfid as string,
          consumer,
          origin,
          id: queryParameters.id as string,
          source: queryParameters.source as string,
        }
      case Consumer.ESSENTIALS:
        return {
          businessId: queryParameters.globalId as string,
          consumer,
          origin,
          id: queryParameters.id as string,
          region: queryParameters.region as Region,
        }
      default:
        return {
          code: queryParameters.code as string,
        }
    }
  }

  public static readonly uriWithoutQueryParameters = (
    fullUri: string
  ): string => fullUri.split('?')[0]
}
