import React from 'react'
import { SplashPage } from '../SplashPage/SplashPage'
import { ErrorSplashImage } from './ErrorSplashImage'
import { TextBlock } from '../widgets/TextBlock'

export const GenericErrorPage = props => {
  return (
    <SplashPage
      className={props.className}
      title={"We'll need to call for reinforcements..."}
      splashImage={<ErrorSplashImage />}
    >
      <TextBlock>
        Something's gone wrong on our end and we're working on it. Please try
        again later.
      </TextBlock>
    </SplashPage>
  )
}
