import React from 'react';
import { FormTemplate } from '@myob/myob-widgets'
import { TabbedNav } from './TabbedNav'
import { AlertContext } from '../../../contexts/AlertContext'
import { Alert } from '../../widgets/Alert'
import { AlertTextMap } from '../../widgets/enums/AlertTextMap'
import { DailyPreferences } from './DailyPreferences'
import { MonthlyPreferences } from './MonthlyPreferences'
import { Header } from '../Header'
import { UpdatePreferencesModal} from './UpdatePreferencesModal'
import { ConsumerContext } from '../../../contexts/ConsumerContext'

export class TabbedPreferences extends React.Component {

    render = () => {
        const alert =
            <AlertContext.Consumer>
                {context => {
                if (context.state.show) {
                    return (
                    <Alert
                        type={context.state.type}
                        onDismiss={context.actions.onDismiss}
                    >
                        {AlertTextMap[context.state.message]}
                    </Alert>
                    )
                }

                    return null
                }}
            </AlertContext.Consumer>
        const dailyModalText = (
            <div>
                <p><b>Reminders will only work for invoices you send through MYOB.</b></p>
                <p>We can only send reminders for invoices that you send from within your MYOB software. If you, say, downloaded the invoice and emailed it separately, we won’t be able to track it.</p>
            </div>
        )
        const monthlyModalText = (
            <div>
                <p><b>You’ll need to be careful about changing invoices.</b></p>
                <p>Remember that the summary emails allow customers to access a live list of all their unpaid invoices. So, if you make a change to an invoice, your customer will see it too.</p>
                <p>We’ll send you a reminder email a few days before we send out the summaries.</p>
            </div>
        )
        const dailyModalHeading = 'Confirm turning on reminders?';
        const monthlyModalHeading = 'Confirm turning on summaries?';
        return (
            <div 
                style={{
                    background: '#ebeef1',
                    height: '100%',
                    width: '100%',
                }}
            >
                <UpdatePreferencesModal 
                    isSmep={this.props.isSmep}
                    showModal={this.props.showModal}
                    hideModal={this.props.hideModal}
                    onAcceptModal={this.props.activeTab==='daily' ? this.props.onUpdateDailyEnabled : this.props.onUpdateMonthlyEnabled}
                    modalText={this.props.activeTab==='daily' ? dailyModalText: monthlyModalText}
                    modalHeading={this.props.activeTab === 'daily' ? dailyModalHeading : monthlyModalHeading}
                    modalType={this.props.activeTab}
                    trackHelpClick={this.props.trackHelpClick}
                    cancelModal={this.props.cancelModal}
                />
                <FormTemplate alert={alert} pageHead={<Header text={'Reminders and unpaid invoice summaries'} isSmep={this.props.isSmep}/>}>
                    <TabbedNav activeTab={this.props.activeTab} changeTab={this.props.changeTab} className="flx-template-form"/>
                    {this.props.activeTab==='daily' ? 
                        <DailyPreferences
                            isSmep={this.props.isSmep}
                            locked={this.props.locked}
                            businessPreferences={this.props.businessPreferences}
                            onUpdateDailyEnabled={this.props.onUpdateDailyEnabled}
                            checkToShowModal={this.props.checkToShowModal}
                            onUpdateDailyAlgorithm={this.props.onUpdateDailyAlgorithm}
                            onUpdateDailySchedule={this.props.onUpdateDailySchedule}
                            onDeleteDailySchedule={this.props.onDeleteDailySchedule}
                        /> : <MonthlyPreferences
                            isSmep={this.props.isSmep}
                            locked={this.props.locked}
                            businessPreferences={this.props.businessPreferences}
                            onUpdateMonthlyEnabled={this.props.onUpdateMonthlyEnabled}
                            onUpdateMonthlyAlgorithm={this.props.onUpdateMonthlyAlgorithm}
                            checkToShowModal={this.props.checkToShowModal}
                        />
                    }
                </FormTemplate>
            </div>
        )
    }
}
