import { BusinessPreferences } from '@myob/sme-cashin-reminders-domain'
import axios from 'axios'
import { AnalyticsService } from '../AnalyticsService'
import { IAnalyticsService } from './../interfaces/IAnalyticsService'

export const createAnalyticsService = (
  url: string,
  businessId: BusinessPreferences['businessId'],
  consumer: BusinessPreferences['consumer'],
  source: string,
  ReactGA: any
): IAnalyticsService => {
  return new AnalyticsService(
    axios.create({
      baseURL: url,
      headers: {
        'x-myob-source': 'api-gateway',
      },
    }),
    businessId,
    consumer,
    source,
    ReactGA
  )
}
