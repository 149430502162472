import { Consumer } from '@myob/sme-cashin-reminders-domain'
import React from 'react'
import { ConsumerContext } from '../../contexts/ConsumerContext'

export type ConsumerRendererProps = {
  readonly [key in Consumer]: React.ReactNode
}

export const ConsumerRenderer: React.FunctionComponent<
  ConsumerRendererProps
> = props => {
  return (
    <ConsumerContext.Consumer>
      {context => {
        switch (context.state.consumer) {
          case Consumer.ACCOUNT_RIGHT_LIVE:
            return props[Consumer.ACCOUNT_RIGHT_LIVE]
          case Consumer.ESSENTIALS:
            return props[Consumer.ESSENTIALS]
          default:
            return null
        }
      }}
    </ConsumerContext.Consumer>
  )
}
