export enum AlertText {
  ERROR_GLOBAL_INIT,
  ERROR_CUSTOMER_INIT,

  BUSINESS_CUSTOMER_SEND_DAILY,
  BUSINESS_CUSTOMER_NOT_SEND_DAILY,
  BUSINESS_CUSTOMER_DAILY_ERROR,

  BUSINESS_CUSTOMER_SEND_MONTHLY,
  BUSINESS_CUSTOMER_NOT_SEND_MONTHLY,
  BUSINESS_CUSTOMER_MONTHLY_ERROR,

  BUSINESS_PREFERENCES_DAILY_ENABLED_UPDATE_FALSE,
  BUSINESS_PREFERENCES_DAILY_ENABLED_UPDATE_TRUE,
  BUSINESS_PREFERENCES_DAILY_ENABLED_UPDATE_ERROR,
  BUSINESS_PREFERENCES_DAILY_ALGORITHM_UPDATE_WHITELISTED,
  BUSINESS_PREFERENCES_DAILY_ALGORITHM_UPDATE_BLACKLISTED,
  BUSINESS_PREFERENCES_DAILY_ALGORITHM_UPDATE_ERROR,
  BUSINESS_PREFERENCES_DAILY_SCHEDULE_DELETE,
  BUSINESS_PREFERENCES_DAILY_SCHEDULE_DELETE_ERROR,
  BUSINESS_PREFERENCES_DAILY_SCHEDULE_UPDATE,
  BUSINESS_PREFERENCES_DAILY_SCHEDULE_UPDATE_ERROR,

  BUSINESS_PREFERENCES_MONTHLY_ENABLED_UPDATE_FALSE,
  BUSINESS_PREFERENCES_MONTHLY_ENABLED_UPDATE_TRUE,
  BUSINESS_PREFERENCES_MONTHLY_ENABLED_UPDATE_ERROR,
  BUSINESS_PREFERENCES_MONTHLY_ALGORITHM_UPDATE_WHITELISTED,
  BUSINESS_PREFERENCES_MONTHLY_ALGORITHM_UPDATE_BLACKLISTED,
  BUSINESS_PREFERENCES_MONTHLY_ALGORITHM_UPDATE_ERROR,
}
