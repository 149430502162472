import {
  flxSpacingSmall,
  flxSpacingTiny,
  flxSpacingXTiny,
  flxSpacingXxLarge,
} from '@myob/myob-styles/dist/design-tokens/js/design-tokens'

export const Spacing = {
  SMALL: flxSpacingXTiny,
  MEDIUM: flxSpacingTiny,
  LARGE: flxSpacingSmall,
  XLARGE: flxSpacingXxLarge,
}
