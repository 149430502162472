import React from 'react'
import styled from 'styled-components'
import { FontSize } from './enums/FontSize'
import { Spacing } from './enums/Spacing'

export type TextBlockProps = {
  readonly children?: React.ReactNode
  readonly className?: string
  readonly bold?: boolean
  readonly color?: string
  readonly small?: boolean
}

const StyledParagraph = styled.p`
  margin-bottom: ${Spacing.MEDIUM};
  font-size: ${(p: TextBlockProps) =>
    p.small ? FontSize.TEXTSMALL : FontSize.TEXT};
  font-weight: ${(p: TextBlockProps) => (p.bold ? 500 : 'inherit')};
  color: ${(p: TextBlockProps) => (p.color ? p.color : 'inherit')};
`

export const TextBlock: React.FunctionComponent<TextBlockProps> = props => {
  return (
    <StyledParagraph
      className={props.className}
      bold={props.bold}
      color={props.color}
      small={props.small}
    >
      {props.children}
    </StyledParagraph>
  )
}
