import { Spinner } from '@myob/myob-widgets'
import { FilterAlgorithm } from '@myob/sme-cashin-reminders-domain'
import React from 'react'
import { Redirect } from 'react-router'
import { CustomerPreferencePage } from '../components/PreferencePage/CustomerPreference/CustomerPreferencePage'
import { AlertText } from '../components/widgets/enums/AlertText'
import { AlertType } from '../components/widgets/enums/AlertType'
import { AlertContextActions } from '../contexts/AlertContext'
import { RoutePath } from '../RoutePath'
import { AnalyticsEvent } from '../services/enums/AnalyticsEvent'
import { BusinessCustomerPreferencesNotFoundError } from '../services/errors/BusinessCustomerNotFoundError'
import { BusinessPreferencesNotFoundError } from '../services/errors/BusinessPreferencesNotFoundError'
import { IAnalyticsService } from '../services/interfaces/IAnalyticsService'
import { IBusinessCustomerPreferencesService } from '../services/interfaces/IBusinessCustomerPreferencesService'
import { IBusinessPreferencesService } from '../services/interfaces/IBusinessPreferencesService'
import { PartialBusinessCustomerPreferences } from '../services/models/PartialBusinessCustomerPreferences'
import { PartialBusinessPreferences } from '../services/models/PartialBusinessPreferences'

export type CustomerProps = {
  readonly className?: string
  readonly onGetBusinessPreferences: IBusinessPreferencesService['get']
  readonly onGetBusinessCustomerPreferences: IBusinessCustomerPreferencesService['get']
  readonly onUpdateBusinessCustomerPreferences: IBusinessCustomerPreferencesService['update']
  readonly onTrack: IAnalyticsService['track']
  readonly onAlert: AlertContextActions['onShow']
  readonly isSmep?: boolean
}

export type CustomerState =
  | {
      readonly businessPreferences: null
      readonly businessCustomerPreferences: null
      readonly loading: true
      readonly locked: boolean
      readonly redirect?: RoutePath
    }
  | {
      readonly businessPreferences: PartialBusinessPreferences
      readonly businessCustomerPreferences: PartialBusinessCustomerPreferences
      readonly loading: false
      readonly locked: false
      readonly redirect?: RoutePath
    }

export class Customer extends React.Component<CustomerProps, CustomerState> {
  private static readonly computeSend = (
    algorithm: FilterAlgorithm,
    status: boolean
  ) => {
    return (
      (algorithm === FilterAlgorithm.BLACKLISTED && !status) ||
      (algorithm === FilterAlgorithm.WHITELISTED && status)
    )
  }
  constructor(props: CustomerProps) {
    super(props)
    this.state = {
      businessPreferences: null,
      businessCustomerPreferences: null,
      loading: true,
      locked: false,
    }
  }

  public async componentDidMount(): Promise<void> {
    await this.onGetPreferences()
  }

  public render(): React.ReactNode {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    if (this.state.loading) {
      return <Spinner />
    }
    return (
      <CustomerPreferencePage
        isSmep={this.props.isSmep}
        className={this.props.className}
        businessPreferences={this.state.businessPreferences}
        sendDaily={Customer.computeSend(
          this.state.businessPreferences.dailyAlgorithm,
          this.state.businessCustomerPreferences.dailyStatus
        )}
        sendMonthly={Customer.computeSend(
          this.state.businessPreferences.monthlyAlgorithm,
          this.state.businessCustomerPreferences.monthlyStatus
        )}
        onUpdateSendDaily={this.onUpdateSendDaily}
        onUpdateSendMonthly={this.onUpdateSendMonthly}
        locked={this.state.locked}
      />
    )
  }

  private readonly onGetPreferences = async () => {
    try {
      const businessPreferences = await this.props.onGetBusinessPreferences()
      await this.onGetCustomerPreferences(businessPreferences)
    } catch (e) {
      const route =
        e instanceof BusinessPreferencesNotFoundError
          ? RoutePath.CUSTOMER_ERROR
          : RoutePath.ERROR
      this.setState({
        redirect: route,
      })
    } finally {
      this.setState({ loading: false })
    }
  }

  private readonly onGetCustomerPreferences = async (
    businessPreferences: PartialBusinessPreferences
  ): Promise<void> => {
    try {
      const businessCustomerPreferences = await this.props.onGetBusinessCustomerPreferences()
      this.setState({
        businessPreferences,
        businessCustomerPreferences,
        loading: false,
      })
    } catch (e) {
      if (!(e instanceof BusinessCustomerPreferencesNotFoundError)) {
        // Redirect to error page if the API return error that is not 404
        this.setState({
          redirect: RoutePath.ERROR,
        })
      } else {
        // Set the checkboxes to default state
        this.setState({
          businessPreferences,
          businessCustomerPreferences: {
            dailyStatus: false,
            monthlyStatus: false,
          },
          loading: false,
        })
      }
    }
    this.props.onTrack(AnalyticsEvent.CUSTOMER_VIEW)
  }

  private readonly onError = (message: AlertText) => {
    this.props.onAlert(AlertType.ERROR, message)
    this.setState({
      loading: false,
    })
  }

  private readonly onSuccess = (message: AlertText) => {
    this.props.onAlert(AlertType.SUCCESS, message)
  }

  private readonly onUpdateBusinessCustomerPreferences = async (
    businessCustomerPreferences: PartialBusinessCustomerPreferences,
    successMessage: AlertText,
    errorMessage: AlertText,
    analyticsEvent: AnalyticsEvent
  ) => {
    try {
      this.setState({ locked: true })

      await this.props.onUpdateBusinessCustomerPreferences(
        businessCustomerPreferences
      )

      this.setState({
        businessCustomerPreferences,
      })

      this.onSuccess(successMessage)

      this.props.onTrack(analyticsEvent, {
        businessPreferences: this.state.businessPreferences,
        businessCustomerPreferences: this.state.businessCustomerPreferences,
      })
    } catch (e) {
      this.onError(errorMessage)
    } finally {
      this.setState({ locked: false })
    }
  }

  private readonly onUpdateSendDaily = async (): Promise<void> => {
    if (this.state.loading) {
      return
    }

    const updatedPreferences = {
      ...this.state.businessCustomerPreferences,
      dailyStatus: !this.state.businessCustomerPreferences.dailyStatus,
    }

    await this.onUpdateBusinessCustomerPreferences(
      updatedPreferences,
      Customer.computeSend(
        this.state.businessPreferences.dailyAlgorithm,
        updatedPreferences.dailyStatus
      )
        ? AlertText.BUSINESS_CUSTOMER_SEND_DAILY
        : AlertText.BUSINESS_CUSTOMER_NOT_SEND_DAILY,
      AlertText.BUSINESS_CUSTOMER_DAILY_ERROR,
      AnalyticsEvent.CUSTOMER_DAILY_STATUS_CHANGE
    )
  }

  private readonly onUpdateSendMonthly = async (): Promise<void> => {
    if (this.state.loading) {
      return
    }

    const updatedPreferences = {
      ...this.state.businessCustomerPreferences,
      monthlyStatus: !this.state.businessCustomerPreferences.monthlyStatus,
    }
    await this.onUpdateBusinessCustomerPreferences(
      updatedPreferences,
      Customer.computeSend(
        this.state.businessPreferences.monthlyAlgorithm,
        updatedPreferences.monthlyStatus
      )
        ? AlertText.BUSINESS_CUSTOMER_SEND_MONTHLY
        : AlertText.BUSINESS_CUSTOMER_NOT_SEND_MONTHLY,
      AlertText.BUSINESS_CUSTOMER_MONTHLY_ERROR,
      AnalyticsEvent.CUSTOMER_MONTHLY_STATUS_CHANGE
    )
  }
}
